import { useContext } from 'react';
import { TranslationStrings } from 'src/core/Translation';
import { ClientConfigContext } from 'src/pages/_app';
import { useTranslations } from '.';

type TranslationKey = {
  id: keyof TranslationStrings;
  props?: {
    [key: string]: string;
  };
};

export const useTranslationKey = ({ id, props }: TranslationKey) => {
  const strings = useTranslations();
  const clientConfig = useContext(ClientConfigContext);
  const clientId: string = clientConfig.clientId;
  //@ts-ignore
  const parsedTranslationKey: keyof TranslationStrings = strings[`${clientId}.${id}`]
    ? `${clientId}.${id}`
    : id;

  return props
    ? Object.keys(props).reduce(
        (acc, key) => acc.replace(`{{${key}}}`, props[key]),
        strings[parsedTranslationKey]
      )
    : strings[parsedTranslationKey];
};

import styled from 'styled-components';

export const Input = styled.input`
  appearance: none;
  height: var(--s-12);
  border: 1px solid var(--color-mediumGrey);
  border-radius: var(--s-1);
  background: var(--color-white);
  text-align: center;
  font-size: var(--text-xl);
  line-height: var(--line-height-xl);
  color: var(--color-darkGrey);
  width: 100%;
`;

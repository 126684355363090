import { useState, useEffect } from 'react';
import { noop } from 'src/lib/Function';
import { UploadedImage } from 'src/core/queries';
import { getImageFromBase64 } from 'src/lib/ImageProcessing/base64Coverter';
import { useConnectionCheck } from 'src/hooks';
import { LocalStorageState } from 'src/hooks/useAppState';
import { Photos } from 'src/hooks/types';
import { Category, ClientImageId } from 'src/Api';
import { LocalStorageStore } from 'src/lib/Storage';
import { trackEvent } from 'src/hooks';
import { trackError } from './useTracking';
import { SessionId } from 'src/Routes';

type UploadComplete = { tag: 'Complete' };
export const UploadComplete: UploadingState = { tag: 'Complete' };

type UploadReady = { tag: 'Ready' };
export const UploadReady: UploadingState = { tag: 'Ready' };

type Uploading = { tag: 'Uploading' };
export const Uploading: UploadingState = { tag: 'Uploading' };

type UploadFailed = { tag: 'Failed' };
export const UploadFailed: UploadingState = { tag: 'Failed' };

export type UploadingState = UploadReady | UploadComplete | Uploading | UploadFailed;

export type CompletedImage = UploadedImage;

export type State = {
  status: UploadingState;
  uploaded: Array<UploadedImage>;
  failed: Array<string>;
};

export type Counter = {
  uploaded: number;
  total: number;
};

type UseUploadImage = {
  state: State;
  progress: Counter;
  uploadImages: (photos: Photos, upload: Upload) => void;
};

type Upload = (category: Category, clientImageId: ClientImageId, file: File) => Promise<any>;
const Store = new LocalStorageStore(LocalStorageState);

export const useUploadImages = (sessionId: SessionId): UseUploadImage => {
  const [state, setState] = useState<State>({
    status: UploadReady,
    uploaded: [],
    failed: [],
  });
  const [progress, setCounter] = useState<Counter>({
    uploaded: state.uploaded.length,
    total: 0,
  });
  const { isOnline } = useConnectionCheck();

  useEffect(() => {
    if (state.status.tag === 'Complete') {
      trackEvent({
        name: 'UploadPhotos - completed',
        data: {},
      });
    }

    if (state.status.tag === 'Uploading') {
      trackEvent({
        name: 'UploadPhotos - started',
        data: {},
      });
    }
  }, [state.status]);

  useEffect(() => {
    Store.get(sessionId).unsafeRunAsync(noop, (mStore) => {
      mStore.foldL(
        () => setState({ ...state }),
        (localStorageState) =>
          localStorageState.sessionId === sessionId &&
          setState({
            ...localStorageState.photosUploadStatus,
            uploaded: localStorageState.photosUploadStatus.uploaded.map((item) => ({
              ...item,
              clientImageId: ClientImageId(item.clientImageId),
            })),
          })
      );
    });
  }, [sessionId]);

  const uploadPromises = (arrayOfPromises: Array<Promise<void>>) =>
    Promise.all(arrayOfPromises)
      .then(() => {
        setState((state) => ({
          ...state,
          status: state.failed.length > 0 ? UploadFailed : UploadComplete,
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          status: UploadFailed,
        }));
        console.error(err);
      });

  const getFailedPhotosArray = (photos: Photos, failed: Array<string>) =>
    failed.map((id: string) => photos[id]);

  const handleUpload = (photos: Photos, upload: Upload): void => {
    const uploadedAlreadyCompleted = state.uploaded.length >= Object.keys(photos).length;
    const hasFailedPhotos = state.failed.length > 0;
    const totalPhotosCount = hasFailedPhotos
      ? state.failed.length + state.uploaded.length
      : Object.keys(photos).length;

    // If all photos are already uploaded, don't do anything
    if (uploadedAlreadyCompleted) {
      setCounter((state) => ({
        ...state,
        uploaded: Object.keys(photos).length,
        total: Object.keys(photos).length,
      }));
      return;
    }

    const uploadProgress = state.uploaded.length;
    if (totalPhotosCount === 0 || !isOnline()) return;

    setState((state) => ({
      ...state,
      status: uploadedAlreadyCompleted ? UploadComplete : Uploading,
    }));

    setCounter((state) => ({
      ...state,
      uploaded: uploadProgress,
      total: totalPhotosCount,
    }));

    // Get Array of photos to facilitate promises creation
    const allPhotosArray = Object.keys(photos).map((item) => photos[item]);
    const failedPhotosArray = getFailedPhotosArray(photos, state.failed);

    if (failedPhotosArray.length > 0) {
      trackEvent({
        name: 'UploadPhotos - retry',
        data: {},
      });
    }

    const photosToUploadArray = hasFailedPhotos ? failedPhotosArray : allPhotosArray;

    const arrayOfPromises = photosToUploadArray.map(async (image, index) => {
      let file = await getImageFromBase64(image.base64, `image-${index}`);

      return upload(image.category, ClientImageId(image.clientImageId), file)
        .then((response) => {
          setState((state) => {
            const failed = hasFailedPhotos ? state.failed.filter((item) => item !== image.id) : state.failed;

            return {
              ...state,
              uploaded: [
                ...state.uploaded,
                { ...response, clientImageId: image.clientImageId, metadata: image.metadata },
              ],
              ...(hasFailedPhotos && { failed }),
            };
          });

          setCounter((state) => ({
            ...state,
            uploaded: state.uploaded + 1,
          }));

          return response;
        })
        .catch((error) => {
          setState((state) => ({
            ...state,
            failed: Array.from(new Set([...state.failed, image.id])),
          }));
          console.error('Failed upload of image with ID: ', image.id);
          trackError({ name: 'Upload image error' }, error);
        });
    });
    uploadPromises(arrayOfPromises);
  };

  return {
    state,
    progress,
    uploadImages: handleUpload,
  };
};

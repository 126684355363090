import { Nominal } from './lib/Nominal';

export type DeviceId = Nominal<string, { readonly DeviceId: unique symbol }>;
export const DeviceId = Nominal<DeviceId>();

export type Token = Nominal<string, { readonly Token: unique symbol }>;
export const Token = Nominal<Token>();

export type SessionId = Nominal<string, { readonly SessionId: unique symbol }>;
export const SessionId = Nominal<SessionId>();

export type Route = Nominal<string, { readonly Route: unique symbol }>;
export const Route = Nominal<Route>();

export const Routes = {
  home: (sessionId: SessionId) => Route(`/${sessionId}`),
  onboarding: (sessionId: SessionId) => Route(`/${sessionId}/onboarding`),
  journey: (sessionId: SessionId) => Route(`/${sessionId}/journey`),
  summary: (sessionId: SessionId) => Route(`/${sessionId}/summary`),
  index: Route('/'),
  bodyshops: (sessionId: SessionId) => Route(`/${sessionId}/bodyshops`),
  bodyshopDetail: (sessionId: SessionId, bodyshopId: string) =>
    Route(`/${sessionId}/bodyshops/${bodyshopId}`),
  cashSettlement: (sessionId: SessionId) => Route(`/${sessionId}/cash-settlement`),
  expired: Route('/expired'),
  wrongDevice: Route('/wrong-device'),
  notFound: Route('/not-found'),
  error: Route('/error'),
  browserNotSupported: Route('/browser-not-supported'),
  api: {
    getImageUploadInfo: Route('/api/getImageUploadInfo'),
    getUploadedImageInfo: Route('/api/getUploadedImageInfo'),
    updateClaim: Route('/api/updateClaim'),
    triggerEndpoint: Route('/api/triggerEndpoint'),
    getClaim: Route('/api/getClaim'),
    sendFeedback: Route('/api/sendFeedback'),
    getSessionClaim: (sessionId: string) => Route(`/api/session/${sessionId}/getSessionClaim`),
    postLinkOpened: (sessionId: string) => Route(`/api/session/${sessionId}/postLinkOpened`),
  },
};

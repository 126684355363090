import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'src/components/primitives/Text';
import { match } from 'src/lib/pattern';
import { Position, TopRight, TopLeft, BottomLeft, BottomRight } from './DropzoneCarTypes';

export const dropzoneCarPreviewImages = [
  {
    src: '/assets/app/front-right.jpg',
    NumberPosition: TopLeft,
  },
  {
    src: '/assets/app/front-left.jpg',
    NumberPosition: TopRight,
  },
  {
    src: '/assets/app/rear-right.jpg',
    NumberPosition: BottomLeft,
  },
  {
    src: '/assets/app/rear-left.jpg',
    NumberPosition: BottomRight,
  },
];

export const DropzoneCarPreview = (): JSX.Element => (
  <Container>
    {dropzoneCarPreviewImages.map((image, index) => (
      <Box key={`${image}+${index}`}>
        <img src={image.src} width={160} height={160} />
        <Number position={image.NumberPosition}>
          <Text as="span" size="lg" weight="medium" color="primary">
            {index + 1}
          </Text>
        </Number>
      </Box>
    ))}
    <VerticleLine />
    <HorizontalLine />
  </Container>
);

const Box = styled.div`
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Number = styled.div<{ position: Position }>`
  position: absolute;
  ${({ position }) =>
    match(position, {
      TopLeft: () => css`
        top: var(--s-1);
        left: var(--s-2);
      `,
      TopRight: () => css`
        top: var(--s-1);
        right: var(--s-2);
      `,
      BottomLeft: () => css`
        bottom: var(--s-1);
        left: var(--s-2);
      `,
      BottomRight: () => css`
        bottom: var(--s-1);
        right: var(--s-2);
      `,
    })}
  }
`;

const Container = styled.div`
  border: 1px solid var(--color-lightGrey);
  border-radius: var(--s-2);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
`;

const VerticleLine = styled.div`
  background: var(--color-lightGrey);
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
`;

const HorizontalLine = styled.div`
  background: var(--color-lightGrey);
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
`;

import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { PhoneCameraOnly } from 'src/core/ClientConfig/DataCollector';

type FileUploader = {
  phoneCameraOnly: PhoneCameraOnly;
  options?: DropzoneOptions;
};

interface InputOptions {
  capture: 'user' | 'environment' | undefined;
}

export const FileUploader: React.FC<FileUploader> = ({
  options: customOptions,
  phoneCameraOnly,
  children,
}) => {
  const options = {
    accept: 'image/*',
    ...customOptions,
  };
  const { getRootProps, getInputProps } = useDropzone(options);
  const inputOptions: InputOptions = { capture: phoneCameraOnly ? 'environment' : undefined };

  return (
    <div
      {...getRootProps()}
      style={{
        outline: 'none',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <input {...getInputProps(inputOptions)} />
      {children}
    </div>
  );
};

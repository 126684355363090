import { useEffect } from 'react';
import * as t from 'io-ts';
import { v4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config';
import { noop } from 'src/lib/Function';
import { Part } from 'src/core/ClientConfig/Part';
import { PointOfImpact } from 'src/components/collectors/DamageLocation';
import { LocalStorageStore } from 'src/lib/Storage';
import { useConnectionCheck } from 'src/hooks';
import { ClientId } from 'src/core/ClientConfig/ClientId';

type WelcomeComplete = {
  name: 'Welcome - finished';
  data: {};
};

type OnboardingComplete = {
  name: 'Onboarding - finished';
  data: {};
};

type VehiclePhotosComplete = {
  name: 'VehiclePhotos - finished';
  data: { numberOfImages: number };
};

type VehicleSidePhotosComplete = {
  name: 'VehicleSidePhotos - finished';
  data: { numberOfImages: number };
};

type PointOfImpactCompleted = {
  name: 'PointOfImpact - finished';
  data: { pointOfImpact: Array<PointOfImpact> };
};

type DamagedPhotosComplete = {
  name: 'DamagedPhotos - finished';
  data: { numberOfImages: number };
};

type AdditionalDetailsComplete = {
  name: 'AdditionalDetails - finished';
  data: {};
};

type DamageConfirmationComplete = {
  name: 'DamageConfirmation - finished';
  data: { damagedParts: Array<Part.Type> };
};

type AdditionalPhotosComplete = {
  name: 'AdditionalPhotos - finished';
  data: { numberOfImages: number };
};

type VehicleMileageComplete = {
  name: 'VehicleMileage - finished';
  data: {};
};

type VinProofComplete = {
  name: 'VinProof - finished';
  data: { numberOfImages: number };
};

type VinComplete = {
  name: 'VIN - finished';
  data: {};
};

type PhotoReviewComplete = {
  name: 'PhotoReview - finished';
  data: { numberOfImages: number };
};

type Rating = {
  name: 'Rating';
  data: { rating: number };
};

type ErrorScreen = {
  name: 'ErrorScreen';
  data: { name: string; description?: string };
};

type UploadPhotosStarted = {
  name: 'UploadPhotos - started';
  data: {};
};

type UploadPhotosRetry = {
  name: 'UploadPhotos - retry';
  data: {};
};

type UploadPhotosCompleted = {
  name: 'UploadPhotos - completed';
  data: {};
};

type BrowserMemoryErrorModal = {
  name: 'BrowserMemoryErrorModal';
  data: {};
};

type SystemErrorModal = {
  name: 'SystemErrorModal';
  data: {};
};

type CtaClick = {
  name: 'CTA Click on';
  data: { ctaDetails: string };
};

type PageView =
  | 'Welcome'
  | 'Onboarding'
  | 'VehiclePhotos'
  | 'VehicleSidePhotos'
  | 'PointOfImpact'
  | 'DamagedPhotos'
  | 'AdditionalDetails'
  | 'DamageConfirmation'
  | 'AdditionalPhotos'
  | 'VinProof'
  | 'Vin'
  | 'PhotoReview'
  | 'AwaitingDone'
  | 'DoneScreen'
  | 'OfflineDetected'
  | 'UploadPhotos - generalError'
  | 'UploadPhotos - offlineError'
  | 'VehicleMileage';

type EventType =
  | WelcomeComplete
  | OnboardingComplete
  | VehiclePhotosComplete
  | VehicleSidePhotosComplete
  | PointOfImpactCompleted
  | DamagedPhotosComplete
  | AdditionalDetailsComplete
  | DamageConfirmationComplete
  | AdditionalPhotosComplete
  | VinProofComplete
  | VehicleMileageComplete
  | VinComplete
  | PhotoReviewComplete
  | Rating
  | ErrorScreen
  | UploadPhotosStarted
  | UploadPhotosCompleted
  | UploadPhotosRetry
  | BrowserMemoryErrorModal
  | SystemErrorModal
  | CtaClick;

type User = {
  clientId: ClientId.Type;
  claimId: string;
};

type Params = {
  name: string;
  data?: any;
  user?: User;
};

const { publicRuntimeConfig } = getConfig();

// TODO: Make this hook returning methods rather than exporting them
const amplitude =
  process.browser && publicRuntimeConfig?.AMPLITUDE_API_KEY
    ? require('amplitude-js')
    : { getInstance: () => ({ init: noop, logEvent: noop, setUserProperties: noop }) };

amplitude.getInstance().init(publicRuntimeConfig?.AMPLITUDE_API_KEY, null, {
  trackingOptions: {
    ip_address: false,
  },
});

const eventsStackStoreName = 'offlineTrackingEventsStack';
const Store = new LocalStorageStore(
  t.record(
    t.string,
    t.type({
      name: t.string,
      data: t.unknown,
    })
  )
);

const addEventToOfflineStack = async ({ name, data }: Params) => {
  const eventId = v4();
  const eventValue = {
    name,
    data,
  };

  Store.get(eventsStackStoreName).unsafeRunAsync(noop, (mStore) => {
    mStore.foldL(
      () => null,
      (stack) => {
        return Store.set(eventsStackStoreName, { ...stack, [`${eventId}`]: eventValue }).unsafeRunAsync(
          noop,
          noop
        );
      }
    );
  });
};

const track = ({ name, data, user }: Params) => {
  if (user) {
    identifyUser(user);
  }
  amplitude.getInstance().logEvent(name, data);
};

const identifyUser = (user: User) => {
  const userProperties = { ...user, type: 'policyholder' };
  amplitude.getInstance().setUserProperties(userProperties);
};

export const setOfflineTrackingStack = () => {
  useEffect(() => {
    Store.set(eventsStackStoreName, {}).unsafeRunAsync(noop, noop);
    // When browser goes back online clear the event stack
    window.ononline = () => {
      // Clear the events stack
      Store.get(eventsStackStoreName).unsafeRunAsync(noop, (mStore) => {
        mStore.foldL(
          () => null,
          (stack) => {
            const keys = Object.keys(stack);
            keys.map((key) => {
              track(stack[key]);
              return key;
            });

            // Clear offline stack
            return Store.set(eventsStackStoreName, {}).unsafeRunAsync(noop, noop);
          }
        );
      });
    };
  }, []);
};

export const useTrackEventOnce = ({ name, data = {} }: { name: PageView; data?: any }) => {
  useEffect(() => {
    const { isOnline } = useConnectionCheck();
    const params = {
      name,
      data,
    };

    isOnline() ? track(params) : addEventToOfflineStack(params);
  }, []);
};

export const useIdentifyUser = (user: User): void => {
  useEffect(() => {
    const { isOnline } = useConnectionCheck();
    const offlineEvent = { name: 'User Online', user };
    isOnline() ? identifyUser(user) : addEventToOfflineStack(offlineEvent);
  }, []);
};

export const trackEvent = (event: EventType) => {
  const { isOnline } = useConnectionCheck();
  const params = {
    name: event.name,
    data: event.data,
  };

  isOnline() ? track(params) : addEventToOfflineStack(params);
};

export const trackError = (eventData: ErrorScreen['data'], error?: unknown) => {
  trackEvent({ name: 'ErrorScreen', data: eventData });
  datadogRum.addError(eventData.name, { ...eventData, error });
};

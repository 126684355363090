import { useState } from 'react';
import { useInterval } from '.';
import { useSetTimeout } from './useSetTimeout';

type Options = {
  /** Whether or not the hook starts polling on initial render */
  initialIsPolling?: boolean;
  /** How long to poll for */
  duration?: number;
};

type PollingControl = {
  /** Function that starts the polling */
  startPolling: () => void;
  /** Function that stops the polling */
  stopPolling: () => void;
  /** The state of the polling function. If we're still polling or not */
  polling: boolean;
};

/**
 * This hook helps for calling a specified callback at a specified interval.
 * The poll can also be declaratively started and stopped
 *
 * /**
 * @param callback The callback being invoked at the specified interval
 * @param interval The time between each call of `callback` (in ms)
 * @param options Optional options to customize the behaviour of the hook
 */
export const usePolling = (
  callback: (control: PollingControl) => void,
  interval: number,
  options?: Options
): PollingControl => {
  const [polling, setPolling] = useState<boolean>(options?.initialIsPolling || false);

  const pollingControl = {
    startPolling: () => setPolling(true),
    stopPolling: () => setPolling(false),
    polling,
  };

  if (options?.duration) {
    useSetTimeout(() => setPolling(false), options?.duration);
  }

  const intervalDelay = polling ? interval : null;
  useInterval(() => callback(pollingControl), intervalDelay);

  return pollingControl;
};

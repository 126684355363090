import * as t from 'io-ts';

export namespace Part {
  export type Type = Codec;
  export type Codec = t.TypeOf<typeof Codec>;
  export const Codec = t.union([
    t.literal('FLWING'),
    t.literal('FRWING'),
    t.literal('BLWING'),
    t.literal('BRWING'),
    t.literal('FLDOOR'),
    t.literal('FRDOOR'),
    t.literal('RLDOOR'),
    t.literal('RRDOOR'),
    t.literal('FBUMPER'),
    t.literal('BBUMPER'),
    t.literal('BONNET'),
    t.literal('BOOTLID'),
    t.literal('LMIRROR'),
    t.literal('RMIRROR'),
    t.literal('LHEADLAMP'),
    t.literal('RHEADLAMP'),
    t.literal('LTAILLAMP'),
    t.literal('RTAILLAMP'),
    t.literal('ROOF'),
    t.literal('GRILLE'),
    t.literal('LROCKERPANEL'),
    t.literal('RROCKERPANEL'),
    t.literal('FWINDSHIELD'),
    t.literal('RWINDSHIELD'),
    t.literal('LAPILLAR'),
    t.literal('RAPILLAR'),
  ]);
  export const serialise = (data: Codec): Type => data;
}

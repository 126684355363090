import { FluidImage } from 'src/core/ClientConfig/Image';
import { vehiclePhotoPreviewImages } from 'src/components/VehiclePhotoPreview';
import { dropzoneCarPreviewImages } from 'src/components/DropzoneCarPreview';
import { dropzoneCarSidePreviewImages } from 'src/components/DropzoneCarSidePreview';
import { ExamplePhoto } from 'src/components/collectors/AdditionalPhotos';

export const usePrecacheImages = () => {
  const precacheCustomImages = (photos: Array<FluidImage.Type | ExamplePhoto>) =>
    photos.map(({ src }) => {
      const image = new Image();
      image.src = src;
    });

  const precacheVehiclePhotoPreview = () =>
    vehiclePhotoPreviewImages.map((src: string) => {
      const image = new Image();
      image.src = src;
    });

  const precacheDropzonePhotoPreview = () =>
    dropzoneCarPreviewImages.map(({ src }: { src: string }) => {
      const image = new Image();
      image.src = src;
    });

  const precacheDropzoneSidePhotoPreview = () =>
    dropzoneCarSidePreviewImages.map(({ src }: { src: string }) => {
      const image = new Image();
      image.src = src;
    });

  return {
    precacheCustomImages,
    precacheVehiclePhotoPreview,
    precacheDropzonePhotoPreview,
    precacheDropzoneSidePhotoPreview,
  };
};

import { createContext } from 'react';
import { BODYSHOP_KEY } from './constants';
import { BodyshopState, BodyshopStateContext as BsContext } from './types';

export const getInitialBodyshopState = (): BodyshopState => {
  const localStorageState =
    typeof window !== 'undefined' && !!localStorage ? localStorage.getItem(BODYSHOP_KEY) : '';
  return localStorageState ? JSON.parse(localStorageState) : {};
};

export const persistBodyshopState = (state: BodyshopState) => {
  typeof window !== 'undefined' &&
    !!localStorage &&
    localStorage?.setItem(BODYSHOP_KEY, JSON.stringify(state));
};

export const clearLocalBodyshop = () => {
  typeof window !== 'undefined' && !!localStorage && localStorage?.removeItem(BODYSHOP_KEY);
};

export const BodyshopStateContext = createContext<BsContext>({
  bodyshopState: null,
  setBodyshopState: () => {},
});

import { useRef } from 'react';

type Options<State = any> = {
  /** The state we want available to the timeout callback function */
  state?: State;
};

/**
 * This hook helps for calling a specified callback after a specified duration
 *
 * /**
 * @param callback The callback being invoked after the timeout
 * @param duration The duration of the timeout
 * @param options Optional options to customize the behaviour of the hook
 */
export const useSetTimeout = <State>(
  callback: (state?: State) => void,
  duration: number,
  options?: Options<State>
): void => {
  const stateRef = useRef(options?.state);
  stateRef.current = options?.state;

  setTimeout(() => callback(stateRef.current), duration);
};

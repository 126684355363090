import * as t from 'io-ts';
import { gql } from 'graphql-request';
import { Part } from './ClientConfig/Part';

export const GET_IMAGE_UPLOAD_INFO = gql`
  query getImageUploadInfo($id: String, $data: ImageInput) {
    getImageUploadInfo(id: $id, data: $data) {
      id
      key
      path
    }
  }
`;

export type GetImageUploadInfoQuery = t.TypeOf<typeof GetImageUploadInfoQuery>;
export const GetImageUploadInfoQuery = t.type({
  getImageUploadInfo: t.type({
    id: t.string,
    key: t.string,
    path: t.string,
  }),
});

export type GetImageUploadInfo = t.TypeOf<typeof GetImageUploadInfo>;
export const GetImageUploadInfo = t.type({
  id: t.string,
  key: t.string,
  path: t.string,
});

export const GET_UPLOADED_IMAGE_INFO = gql`
  query getUploadedImageInfo($key: String) {
    getUploadedImageInfo(key: $key) {
      id
      format
      key
      bucket
      storageLocation
      submittedTimestamp
      path
      signedUrl
    }
  }
`;

export type Category = t.TypeOf<typeof Category>;
export const Category = t.union([
  t.literal('vp'),
  t.literal('ap'),
  t.literal('pr'),
  t.literal('dp'),
  t.literal('vin'),
]);

export type ProcessedImage = t.TypeOf<typeof ProcessedImage>;
export const ProcessedImage = t.type({
  id: t.string,
  format: t.string,
  key: t.string,
  bucket: t.string,
  storageLocation: t.string,
  submittedTimestamp: t.string,
  path: t.string,
  signedUrl: t.string,
});

export type UnprocessedImage = t.TypeOf<typeof UnprocessedImage>;
export const UnprocessedImage = t.type({
  id: t.string,
  category: Category,
  base64: t.string,
  clientImageId: t.string,
  metadata: t.type({
    originalCreationTimestamp: t.string,
    metadata: t.string,
  }),
});

export type UploadedImage = t.TypeOf<typeof UploadedImage>;
export const UploadedImage = t.type({
  id: t.string,
  format: t.string,
  key: t.string,
  bucket: t.string,
  storageLocation: t.string,
  submittedTimestamp: t.string,
  path: t.string,
  signedUrl: t.string,
  clientImageId: t.string,
  metadata: t.type({
    originalCreationTimestamp: t.string,
    metadata: t.string,
  }),
});

export type GetUploadedImageInfoQuery = t.TypeOf<typeof GetUploadedImageInfoQuery>;
export const GetUploadedImageInfoQuery = t.type({ getUploadedImageInfo: ProcessedImage });

export const GET_CLAIM = gql`
  query getClaim {
    claim {
      id
      stage
      status
      images {
        id
      }
      detectedParts
      damagedParts {
        id
        damaged
      }
      clientClaimId
      processabilityTriageOutcome
      estimate {
        info {
          amountIncludingTax
          taxRate
          taxStrategy {
            strategy
            rate
            bands {
              to
              from
              rate
            }
          }
          repairLaborRate
          stripRefitLaborRate
          paintLaborRate
          uncertaintyMeasure
          currencyCode
          confident
          flags
          customerLanguage
          internalLanguage
          repairDataRegion {
            countryCode
            region
          }
          additionalDamage
          estimateType
        }
        paint {
          additionalLaborHours
          additionalMaterialCost
          doubleLayerLaborHours
          finishingLaborHours
          miscLaborHours
          consumablesCost
        }
        parts {
          name
          belongsTo
          operation
          stripRefitLaborHours
          paintLaborHours
          repairLaborHours
          replacementPartCost
          paintMaterialCost
          partCategory
          oePartIdentifier
          partType
          undeductedStripRefitLaborHours
          partSupplier
          displayPartIdentifier
          partManufacturer
          appliedAdjustments
          paintLevel
          paintPartMaterial
          dataSource
          internalDisplayName
          customerDisplayName
          partOptions
          partSelection
        }
        additionalCosts {
          descriptor
          cost
        }
        additionalHours {
          descriptor
          hours
          rate
        }
        createdAt
      }
    }
  }
`;

export type Stage = t.TypeOf<typeof Stage>;
export const Stage = t.union([
  t.literal('CLAIM_CREATION'),
  t.literal('POLICYHOLDER_INPUT'),
  t.literal('AI_IMAGE_CLASSIFICATION'),
  t.literal('AI_DAMAGED_PARTS_OUTPUT'),
  t.literal('AI_ESTIMATE_GENERATION'),
  t.literal('AI_ESTIMATE_EDITING'),
  t.literal('AUTO_TRIAGE'),
  t.literal('AUTO_ESTIMATE'),
  t.literal('HUMAN_TRIAGE'),
  t.literal('HUMAN_ESTIMATE_GENERATION'),
  t.literal('ESTIMATE_MAPPING'),
  t.literal('ESTIMATE_REVIEW'),
  t.literal('ESTIMATE_PUBLISHING'),
  t.literal('CLAIM_DELETION'),
  t.literal('EXTERNAL_ESTIMATE_EDITING'),
  t.literal('NEGOTIATION'),
  t.literal('PH_ESTIMATE_DECISION'),
  t.literal('EXTERNAL_ESTIMATE_EDITING'),
  t.literal('TEP_ESTIMATE_EDITING'),
  t.literal('ESTIMATE_DECISION'),
  t.literal('NEGOTIATION_EDITING'),
  t.literal('NEGOTIATION'),
]);

export type Status = t.TypeOf<typeof Status>;
export const Status = t.union([
  t.literal('CREATED'),
  t.literal('PROCESSING_IMAGE_RESULTS'),
  t.literal('PROCESSED_IMAGE_RESULTS'),
  t.literal('PROCESSING_DAMAGED_PARTS'),
  t.literal('PROCESSED_DAMAGED_PARTS'),
  t.literal('PROCESSING_ESTIMATE'),
  t.literal('PROCESSED_ESTIMATE'),
  t.literal('EDITING_ESTIMATE'),
  t.literal('WAITING_FOR_INPUT'),
  t.literal('PENDING'),
  t.literal('IN_PROGRESS'),
  t.literal('DONE'),
  t.literal('READY'),
  t.literal('DISCARDED'),
  t.literal('FAILED'),
  t.literal('FAILED_MAPPING'),
  t.literal('FAILED_ESTIMATING'),
  t.literal('FAILED_INSUFFICIENT_PHOTOS'),
  t.literal('FAILED_OUT_OF_SCOPE'),
  t.literal('FAILED_SEVERE_DAMAGE'),
  t.literal('FAILED_OBVIOUS_TOTAL_LOSS'),
  t.literal('FAILED_OUT_OF_HOURS'),
  t.literal('FAILED_NON_PROCESSABLE'),
]);

export const Flag = t.union([
  t.literal('oem-3-year'),
  t.literal('no-low-quality-parts'),
  t.literal('parts-25'),
  t.literal('paint-material-33'),
  t.literal('strip-manual-override'),
  t.string,
]);

export const Estimate = t.type({
  info: t.type({
    amountIncludingTax: t.union([t.null, t.number]),
    taxRate: t.union([t.null, t.number]),
    taxStrategy: t.union([
      t.null,
      t.type({
        strategy: t.string,
        rate: t.union([t.null, t.number]),
        bands: t.union([
          t.null,
          t.array(
            t.type({
              from: t.number,
              to: t.union([t.null, t.number]),
              rate: t.number,
            })
          ),
        ]),
      }),
    ]),
    repairLaborRate: t.union([t.null, t.number]),
    stripRefitLaborRate: t.union([t.null, t.number]),
    paintLaborRate: t.union([t.null, t.number]),
    uncertaintyMeasure: t.union([t.null, t.number]),
    currencyCode: t.string,
    confident: t.boolean,
    flags: t.array(Flag),
    customerLanguage: t.string,
    internalLanguage: t.literal('EN'),
    repairDataRegion: t.type({
      countryCode: t.string,
      region: t.union([t.null, t.string]),
    }),
    additionalDamage: t.boolean,
    estimateType: t.union([
      t.literal('DETAILED'),
      t.literal('ANIA'),
      t.literal('SIMPLIFIED'),
      t.literal('ASO'),
    ]),
  }),
  paint: t.type({
    additionalLaborHours: t.union([t.null, t.number]),
    additionalMaterialCost: t.union([t.null, t.number]),
    doubleLayerLaborHours: t.union([t.null, t.number]),
    finishingLaborHours: t.union([t.null, t.number]),
    miscLaborHours: t.union([t.null, t.number]),
    consumablesCost: t.union([t.null, t.number]),
  }),
  parts: t.array(
    t.type({
      name: t.string,
      belongsTo: t.string,
      operation: t.string,
      stripRefitLaborHours: t.number,
      paintLaborHours: t.number,
      repairLaborHours: t.number,
      replacementPartCost: t.number,
      paintMaterialCost: t.number,
      partCategory: t.union([t.literal('MAIN'), t.literal('AUX')]),
      oePartIdentifier: t.union([t.string, t.null]),
      partType: t.union([
        t.literal('OEM'),
        t.literal('OE_CUSTOM'),
        t.literal('NON_OEM'),
        t.literal('NON_OEM_TH'),
        t.literal('NON_OEM_CZ'),
        t.literal('NON_OEM_LOW_QUALITY'),
        t.literal('NON_OEM_SUP'),
        t.string,
      ]),
      undeductedStripRefitLaborHours: t.number,
      partSupplier: t.union([t.literal('OEM'), t.string, t.null]),
      displayPartIdentifier: t.union([t.string, t.null]),
      partManufacturer: t.union([t.string, t.null]),
      appliedAdjustments: t.array(Flag),
      paintLevel: t.union([t.string, t.null]),
      paintPartMaterial: t.union([t.string, t.null]),
      dataSource: t.string,
      internalDisplayName: t.string,
      customerDisplayName: t.string,
      partOptions: t.array(t.string),
      partSelection: t.string,
    })
  ),
  additionalCosts: t.array(
    t.type({
      descriptor: t.string,
      cost: t.number,
    })
  ),
  additionalHours: t.array(
    t.type({
      descriptor: t.string,
      hours: t.number,
      rate: t.number,
    })
  ),
  createdAt: t.union([t.undefined, t.string]),
});

export type Claim = t.TypeOf<typeof Claim>;
export const Claim = t.type({
  id: t.union([t.null, t.string]),
  stage: Stage,
  status: Status,
  images: t.array(
    t.type({
      id: t.string,
    })
  ),
  detectedParts: t.array(Part.Codec),
  damagedParts: t.array(
    t.type({
      id: Part.Codec,
      damaged: t.boolean,
    })
  ),
  clientClaimId: t.union([t.null, t.string]),
  estimate: t.union([Estimate, t.null]),
  processabilityTriageOutcome: t.union([t.string, t.null]),
});

export type GetClaim = t.TypeOf<typeof GetClaim>;
export const GetClaim = t.type({ claim: Claim });

export const UPDATE_CLAIM = gql`
  mutation updateClaim($data: ClaimInput) {
    updateClaim(data: $data) {
      id
    }
  }
`;

export type UpdateClaim = t.TypeOf<typeof UpdateClaim>;
export const UpdateClaim = t.type({
  updateClaim: t.type({ id: t.union([t.null, t.string]) }),
});

export const TRIGGER_ENDPOINT = gql`
  query triggerEndpoint($endpoint: String) {
    triggerEndpoint(endpoint: $endpoint) {
      id
    }
  }
`;

export type TriggerEndpointResult = t.TypeOf<typeof TriggerEndpointResult>;
export const TriggerEndpointResult = t.type({
  triggerEndpoint: t.type({
    id: t.union([t.null, t.string]),
  }),
});

export const SEND_FEEDBACK = gql`
  mutation sendFeedback($response: String) {
    sendFeedback(response: $response) {
      submitted
    }
  }
`;

export const SendFeedbackResult = t.type({
  sendFeedback: t.type({
    submitted: t.boolean,
  }),
});

import React, { createContext } from 'react';
import Head from 'next/head';
import { datadogRum } from '@datadog/browser-rum';

import { AppProps } from 'next/app';
import { Preflight } from 'src/components/styles/Preflight';
import { GlobalAppStyles } from 'src/components/styles/GlobalAppStyles';
import { Api } from 'src/Api';
import { ClientConfig } from 'src/core/ClientConfig';
import defaultConfig from '../configs/tractable.json';
import getConfig from 'next/config';
import { BodyshopStateContext } from 'src/store/bodyshop/context';
import { useBodyshopState } from 'src/hooks/useBodyshopState';

const api = new Api();

export type PageProps = {
  api: Api;
};

const { publicRuntimeConfig } = getConfig();

const runtimeEnv = publicRuntimeConfig?.ENV;
const runtimeAppRegion = publicRuntimeConfig?.APP_REGION;

const envWithAppRegion = process.env.ENV === 'dev' ? runtimeEnv : `${runtimeEnv}-${runtimeAppRegion}`;

datadogRum.init({
  applicationId: '6cc3bc7c-129e-4e7b-9d0d-9227e7c88ed5',
  clientToken: 'pub2713ed25fdae8f2441e53adea569a894',
  site: 'datadoghq.eu',
  env: envWithAppRegion,
  service: 'policyholder-app-nitro',
  version: process.env.PACKAGE_VERSION,
  sampleRate: 100,
  trackInteractions: true,
});

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  typeof window === 'undefined'
    ? import('../../playwright/mocks/server').then(({ server }) => {
        server.listen({
          onUnhandledRequest: process.env.CI ? 'bypass' : 'warn',
        });
      })
    : import('../../playwright/mocks/browser').then(({ worker }) => {
        worker.start();
      });
}

// TODO find solution for the default object type
export const ClientConfigContext = createContext<ClientConfig.Type | any>(defaultConfig);

const App = ({ Component, pageProps }: AppProps<PageProps>): JSX.Element => {
  const { bodyshopState, setBodyshopState } = useBodyshopState();

  return (
    <ClientConfigContext.Provider value={pageProps.clientConfig}>
      <BodyshopStateContext.Provider value={{ bodyshopState, setBodyshopState }}>
        <Head>
          <link
            rel="icon"
            type="image/x-icon"
            href={pageProps.clientConfig?.theme.faviconPath || '/assets/app/favicon.ico'}
            sizes="32x32"
          />
        </Head>
        <Preflight />
        <GlobalAppStyles />
        <Component {...pageProps} api={api} />
      </BodyshopStateContext.Provider>
    </ClientConfigContext.Provider>
  );
};

export default App;

import { match } from './pattern';
import { Maybe } from './Maybe';
import { noop } from './Function';

type unit = void;

/**
  This is a type alias for IO<A, never>, which represents an effect
  that cannot fail, but can succeed with an A.
*/
export type UIO<A> = IO<A, never>;

export class IO<A, E> {
  /**
    IO.**pure** constructs a success IO from the provided value.
  */
  static pure = <A, E>(a: A): IO<A, E> => new IO<A, E>({ tag: 'Pure', a });

  /**
    IO.**unit** represents an IO that successfully resolves to void.
  */
  static unit = IO.pure<void, never>(undefined);

  /**
    IO.**fail** wraps a strictly-evaluated error value in a failure IO.
  */
  static fail = <A, E>(e: E): IO<A, E> => new IO<A, E>({ tag: 'Fail', e });

  /**
    IO.**sync** wraps a lazily-evaluated value of type A in a success IO.
  */
  static sync = <A, E>(lazy: () => A): IO<A, E> => IO.suspend<A, E>(() => IO.pure<A, E>(lazy()));

  /**
    IO.**syncFail** wraps a lazily-evaluated value of type E in a failure IO.
  */
  static syncFail = <A, E>(lazy: () => E): IO<A, E> => IO.suspend<A, E>(() => IO.fail<A, E>(lazy()));

  /**
    IO.**suspend** wraps a lazily-evaluated IO value in an IO.
  */
  static suspend = <A, E>(lazy: () => IO<A, E>): IO<A, E> => new IO<A, E>(new Suspend(lazy));

  /**
    IO.**async** creates an async IO value that is run by invoking a callback.

    Example:

    ```ts
    IO.async((onF, onS) => onS(1));
    IO.async((onF, onS) => onF(new Error('Failed')));
    IO.async((onF, onS, onE) => onE(new Error('Failed')));
    ```
  */
  static async = <A, E>(onDone: (onFailure: (e: E) => unit, onSuccess: (a: A) => unit) => unit): IO<A, E> =>
    new IO<A, E>({ tag: 'Async', onDone });

  /**
    IO.**fromPromise** lifts a lazyily-evaluated promise into an async IO value.
  */
  static fromPromise = <A, E = Error>(lazy: () => Promise<A>): IO<A, E> =>
    IO.async((onFailure, onSuccess) => lazy().then(onSuccess).catch(onFailure));

  /**
    IO.**fromMaybe** lifts an evaluated Maybe<A> to an IO<A, E> by providing a
    fallback E value to use when the Maybe is `Nothing`.
  */
  static fromMaybe =
    <A, E>(fallback: E) =>
    (ma: Maybe<A>): IO<A, E> =>
      ma.fold(IO.fail<A, E>(fallback), (a) => IO.pure<A, E>(a));

  /**
    IO.**fromNullable** lifts an evaluated nullable value of type A to an IO<A, E> by providing a
    fallback E value to use when the value is `null` or `undefined`.
  */
  static fromNullable =
    <A, E>(fallback: E) =>
    (a: A | null | undefined): IO<A, E> =>
      a === null || a === undefined ? IO.fail(fallback) : IO.pure(a);

  /**
    IO.**tryCatch** lifts a lazy function (() => A) that can throw an unknown exception to an IO<A, unknown>.
  */
  static tryCatch = <A>(f: () => A): IO<A, unknown> => {
    try {
      return IO.pure(f());
    } catch (e) {
      return IO.fail(e);
    }
  };

  private constructor(private readonly value: t<A, E>) {}

  /**
   * IO.**flatMapPair** applies a function (**A** => IO<**B**, E1>) on an IO<**A**, E> producing an IO<[**A**, **B**], E | E1>
   *
   * Useful when you want to inject a second IO-wrapped value into an existing IO.
   */
  flatMapPair = <B, E1>(f: (a: A) => IO<B, E1>): IO<[A, B], E | E1> =>
    this.flatMap((a) => f(a).map((b) => [a, b]));

  /**
   * IO.**mapPair** applies a function (**A** => **B**) on an IO<**A**, E> producing an IO<[**A**, **B**], E>
   *
   * Useful when you want inject a second value into an IO.
   */
  mapPair = <B>(f: (a: A) => B): IO<[A, B], E> => this.map((a) => [a, f(a)]);

  /**
    IO.**map** applies a function (**A** => **B**) on an IO<**A**, E> success channel to produce an IO<**B**, E>.
  */
  map = <B>(f: (a: A) => B): IO<B, E> => this.flatMap((a) => IO.pure(f(a)));

  /**
    IO.**mapFailure** applies a function (**E** => **E1**) on an IO<A, **E**> failure channel to produce an
    IO<A, **E1**>.
  */
  mapFailure = <E1>(f: (e: E) => E1): IO<A, E1> => this.flatMapFailure((e) => IO.fail(f(e)));

  /**
    IO.**bimap** applies map functions on both the success and failure channels of the IO. Equivalent to:

    ```ts
    io.map(f).mapFailure(g);
    ```
  */
  bimap = <B, E1>(f: (a: A) => B, g: (e: E) => E1): IO<B, E1> => this.map(f).mapFailure(g);

  /**
    IO.**flatMap** applies an effectful function (A => IO<B, E1>) on the success channel
    to produce an IO<B, E | E1>.

    _Note:_ The failure channel type will grow from the original E to include the new E1.
  */
  flatMap = <B, E1>(f: (a: A) => IO<B, E1>): IO<B, E | E1> => new IO(new FlatMap<B, E | E1, A>(f, this));

  /**
    IO.**flatMapFailure** applies an effectful function (E => IO<A, E1>) on the failure channel
    to produce an IO<A, E1>.

    _Note:_ The success channel type will grow from the original A to include the new B.
  */
  flatMapFailure = <E1>(f: (e: E) => IO<A, E1>): IO<A, E1> => new IO(new FlatMapFailure<A, E1, E>(f, this));

  /**
    IO.**biFlatMap** applies flatMap functions on both the success and failure channels of the IO. Equivalent to:
    ```ts
    io.flatMap(f).flatMapFailure(g);
    ```
  */
  biFlatMap = <B, E1>(f: (a: A) => IO<B, E>, g: (e: E) => IO<B, E1>): IO<B, E1> =>
    this.flatMap(f).flatMapFailure(g);

  filterOrFail = <E1>(p: (a: A) => boolean, e: E1): IO<A, E | E1> =>
    this.flatMap((a) => (p(a) ? IO.pure(a) : IO.fail(e)));

  filterOrElse = <A1>(p: (a: A) => boolean, f: (a: A) => IO<A | A1, E>): IO<A | A1, E> =>
    this.flatMap((a) => (p(a) ? IO.pure<A | A1, E>(a) : f(a)));

  /**
    IO.**handleFailure** uses a function (E => A) to convert a failed IO to a success IO, which
    clears the failure in the IO returning IO<A, never>.
  */
  handleFailure = (f: (e: E) => A): IO<A, never> => this.flatMapFailure((e) => IO.pure(f(e)));

  delay = (time: number): IO<A, E> =>
    IO.async<A, E>((_, onSuccess) => setTimeout(onSuccess, time)).flatMap(() => this);

  /**
    IO.**tap** applies a side-effect function (A => unit) on an IO<A, E> success channel,
    and propagates the A value unchanged.

    This is useful for doing things like logging the value inside the IO.
  */
  tap = (f: (a: A) => unit): IO<A, E> =>
    this.map((a) => {
      f(a);
      return a;
    });

  biTap = (f: (a: A) => unit, g: (e: E) => unit): IO<A, E> =>
    this.map((a) => {
      f(a);
      return a;
    }).mapFailure((b) => {
      g(b);
      return b;
    });

  biLog: (msg: string) => IO<A, E> = (msg) =>
    this.biTap(
      (a) => console.log('pure', msg, a),
      (b) => console.log('fail', msg, b)
    );

  flatTap = (f: (a: A) => UIO<unit>): IO<A, E> => this.flatMap((a) => f(a).map(() => a));

  /**
    IO.**tapFailure** applies a side-effect function (E => unit) on an IO<A, E> failure channel,
    and propagates the E value unchanged.

    This is useful for doing things like logging the value inside the IO.
  */
  tapFailure = (f: (e: E) => unit): IO<A, E> =>
    this.mapFailure((e) => {
      f(e);
      return e;
    });

  /**
    IO.**orDie** will throw the E value if ran on a failed IO and will eliminate the E type
    completely resulting in an IO<A, never>.

    This is useful for dealing with exceptions that cannot be recovered in the IO context.
  */
  orDie = (): UIO<A> => this.flatMapFailure((e) => new IO<A, never>({ tag: 'Die', e }));

  /**
    IO.**unsafeRunAsync** runs the effectual IO<A, E> and applies the given onFailure and
    onSuccess callbacks to the outcome of the IO depending on the channel the IO ends in.

    This function should be run "at the edge of the world" to evaluate the
    suspended side-effects in the IO.
  */
  unsafeRunAsync = (
    onFailure: (e: E) => unit,
    onSuccess: (a: A) => unit,
    onException: (u: unknown) => unit = noop
  ): unit => {
    match(this.value, {
      Die: ({ e }) => onException(e),
      Pure: ({ a }) => onSuccess(a),
      Fail: ({ e }) => onFailure(e),
      Suspend: ({ lazy }) => lazy().unsafeRunAsync(onFailure, onSuccess, onException),
      Async: ({ onDone }) => onDone(onFailure, onSuccess),
      FlatMap: ({ ioA, aToIOB }) =>
        ioA.unsafeRunAsync(
          onFailure,
          (a) => aToIOB(a).unsafeRunAsync(onFailure, onSuccess, onException),
          onException
        ),
      FlatMapFailure: ({ ioE, eToIOE1 }) =>
        ioE.unsafeRunAsync(
          (e) => eToIOE1(e).unsafeRunAsync(onFailure, onSuccess, onException),
          onSuccess,
          onException
        ),
    });
  };

  /**
    IO.**toPromise** runs the effectual IO<A, E> and lifts the outcome into a `Promise`.
    In doing so it throws away the failure channel (E) type information.

    This function should be run "at the edge of the world" to evaluate the
    suspended side-effects in the IO.
  */
  // TODO: change this to yield a Promise<Result<A, E>> with the exception moved to the rejection channel or to an Exit type
  toPromise = (onException: (e: unknown) => unit): Promise<A> =>
    new Promise((res, rej) => this.unsafeRunAsync(rej, res, onException));
}

type t<A, E> =
  | Pure<A>
  | Die
  | Fail<E>
  | Suspend<A, E>
  | Async<A, E>
  | FlatMap<A, E, any>
  | FlatMapFailure<A, E, any>;

type Pure<A> = { tag: 'Pure'; a: A };
type Die = { tag: 'Die'; e: unknown };
type Fail<E> = { tag: 'Fail'; e: E };
type Async<A, E> = {
  tag: 'Async';
  onDone: (onFailure: (e: E) => unit, onSuccess: (a: A) => unit) => unit;
};
class Suspend<A, E> {
  tag: 'Suspend' = 'Suspend';
  _E!: E;
  _A!: A;

  constructor(public readonly lazy: () => IO<A, E>) {}
}
class FlatMap<A, E, A0> {
  tag: 'FlatMap' = 'FlatMap';
  _E!: E;
  _A!: A;
  _A0!: A0;

  constructor(public readonly aToIOB: (a0: A0) => IO<A, E>, public readonly ioA: IO<A0, E>) {}
}

class FlatMapFailure<A, E, E0> {
  tag: 'FlatMapFailure' = 'FlatMapFailure';
  _E!: E;
  _A!: A;
  _E0!: E0;

  constructor(public readonly eToIOE1: (e0: E0) => IO<A, E>, public readonly ioE: IO<A, E0>) {}
}

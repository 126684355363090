type Union<Discriminant extends string> = { [Literal in Discriminant]: string };

type InferMatch<
  Discriminant extends string,
  ADT extends Union<Discriminant>,
  Type extends ADT[Discriminant]
> = Extract<ADT, { [Literal in Discriminant]: Type }>;

type ExhaustivePattern<Discriminant extends string, ADT extends Union<Discriminant>, A> = {
  [Kind in ADT[Discriminant]]: (m: InferMatch<Discriminant, ADT, Kind>) => A;
};

type CatchAll<Discriminant extends string, ADT extends Union<Discriminant>, A> = { _: (adt: ADT) => A };

type PartialPattern<Discriminant extends string, ADT extends Union<Discriminant>, A> = Partial<
  ExhaustivePattern<Discriminant, ADT, A>
> &
  CatchAll<Discriminant, ADT, A>;

type Pattern<Discriminant extends string, ADT extends Union<Discriminant>, A> =
  | ExhaustivePattern<Discriminant, ADT, A>
  | PartialPattern<Discriminant, ADT, A>;

export const genericMatch =
  <Discriminant extends string>(d: Discriminant) =>
  <ADT extends Union<Discriminant>, A>(adt: ADT, pattern: Pattern<Discriminant, ADT, A>): A => {
    const match = pattern[adt[d]];
    if (typeof match === 'function') {
      return match(adt as Extract<ADT, { [Literal in Discriminant]: string }>);
    }

    return (pattern as CatchAll<Discriminant, ADT, A>)._(adt);
  };

export const match = genericMatch('tag');

export const gqlMatch = genericMatch('__typename');

export const curryMatch =
  <ADT extends Union<'tag'>, A>(pattern: Pattern<'tag', ADT, A>) =>
  (adt: ADT): A =>
    genericMatch('tag')(adt, pattern);

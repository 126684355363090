import styled from 'styled-components';
import Select from 'react-select';

import { Vin as VinType } from 'src/Api';
import { Label } from 'src/components/Label';
import { Active as ActiveDc, InactiveDataCollector } from 'src/components/DataCollector';
import { FluidImage } from 'src/core/ClientConfig/Image';
import { Translation } from '../../Translation';
import { Input } from '../../primitives/Input';
import { trackEvent, useTrackEventOnce } from 'src/hooks';
import { ContinueButton } from '../../Buttons';
import { InputErrorMessage } from 'src/components/InputErrorMessage';

import { useVin } from './useVin';
import { MMYScreen, MMYContainer } from './MMYInputScreen';
import { VinScreen } from './VinInputScreen';

type VinProps = {
  id: string;
  examplePhotos: Array<FluidImage.Type>;
  hideCommonLocations: boolean;
  allowMMYEntry: boolean;
  value: VinType;
  number: number;
  onContinue: (value: VinType) => void;
  setActive: () => void;
  isActive: boolean;
  saved?: boolean;
};

export const Vin = ({
  id,
  examplePhotos,
  value,
  onContinue,
  setActive,
  isActive,
  number,
  saved,
  hideCommonLocations,
  allowMMYEntry,
}: VinProps) =>
  isActive ? (
    <Active
      id={id}
      examplePhotos={examplePhotos}
      onContinue={onContinue}
      allowMMYEntry={allowMMYEntry}
      hideCommonLocations={hideCommonLocations}
      value={value}
      number={number}
    />
  ) : (
    <Inactive id={id} number={number} setActive={setActive} value={value} saved={saved} />
  );

const Heading = ({ number }: { number: number }) => (
  <>
    {number}. <Translation id="journey.dataCollector.vin.name" />
  </>
);

type Active = {
  id: string;
  value: VinType;
  examplePhotos: Array<FluidImage.Type>;
  hideCommonLocations: boolean;
  allowMMYEntry: boolean;
  onContinue: (value: VinType) => void;
  number: number;
};

const Active = ({
  id,
  examplePhotos,
  hideCommonLocations,
  allowMMYEntry,
  onContinue,
  number,
  value,
}: Active) => {
  useTrackEventOnce({ name: 'Vin' });
  const {
    isValid,
    isVinEmpty,
    vinHasInvalidLetters,
    onContinueData,
    vinEntryMode,
    toggleVinInputScreen,
    vinInputProps,
    modelInputProps,
    makeInputProps,
    yearInputProps,
  } = useVin(value, allowMMYEntry);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        trackEvent({
          name: 'VIN - finished',
          data: {},
        });
        onContinue({ ...onContinueData, submitted: true });
      }}
    >
      <ActiveDc
        id={id}
        header={<Heading number={number} />}
        button={<ContinueButton type="submit" disabled={!isValid} />}
      >
        {vinEntryMode ? (
          <VinScreen
            hideCommonLocations={hideCommonLocations}
            allowMMYEntry={allowMMYEntry}
            examplePhotos={examplePhotos}
            showMMYScreen={toggleVinInputScreen}
          >
            <Label htmlFor="vin">
              <Translation id="journey.dataCollector.vin.input.label" />
            </Label>
            <StyledInput
              data-testid="input-vin"
              type="text"
              name="vin"
              hasError={!isValid && !isVinEmpty}
              {...vinInputProps}
            />
            {!isValid &&
              !isVinEmpty &&
              (vinHasInvalidLetters ? (
                <InputErrorMessage>
                  <Translation id="journey.dataCollector.vin.input.error.invalidCharacters" />
                </InputErrorMessage>
              ) : (
                <InputErrorMessage>
                  <Translation id="journey.dataCollector.vin.input.error.invalid" />
                </InputErrorMessage>
              ))}
          </VinScreen>
        ) : (
          <MMYScreen showVinScreen={toggleVinInputScreen}>
            <MMYContainer>
              <Box>
                <Label htmlFor="make">
                  <Translation id="journey.dataCollector.vin.select.make.label" />
                </Label>
                <Select data-testid="select-make" name="make" {...makeInputProps} />
              </Box>
              <Box>
                <Label htmlFor="model">
                  <Translation id="journey.dataCollector.vin.select.model.label" />
                </Label>
                <Select data-testid="select-model" name="model" {...modelInputProps} />
              </Box>
              <Box>
                <Label htmlFor="year">
                  <Translation id="journey.dataCollector.vin.select.year.label" />
                </Label>
                <Select data-testid="select-year" name="year" {...yearInputProps} />
              </Box>
            </MMYContainer>
          </MMYScreen>
        )}
      </ActiveDc>
    </form>
  );
};

type Inactive = {
  id: string;
  value: VinType;
  number: number;
  setActive: () => void;
  saved?: boolean;
};

const tag = (saved: boolean | undefined, value: VinType) => {
  if (saved) return 'Saved';

  if (value.vin.length > 0) {
    return 'Edit';
  }

  if (value.make.length > 0 && value.model.length > 0 && !!value.year) {
    return 'Edit';
  }
  return 'Disabled';
};

export const Inactive = ({ id, value, number, setActive, saved }: Inactive): JSX.Element => (
  <InactiveDataCollector<VinType>
    id={id}
    tag={tag(saved, value)}
    heading={<Heading number={number} />}
    value={value}
    onEdit={setActive}
  >
    {value.vinEntryMode ? value.vin : `${value.make} ${value.model} (${value.year})`}
  </InactiveDataCollector>
);

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

interface InputProps {
  hasError: boolean;
}

const StyledInput = styled(Input)<InputProps>`
  border: 1px solid ${(props) => (props.hasError ? 'var(--color-negative)' : 'var(--color-mediumGrey)')};
`;

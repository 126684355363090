import styled, { css } from 'styled-components';
import { Action } from './Action';

const buttonCss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: var(--button-transform);
  letter-spacing: var(--button-letter-spacing);
  font-weight: var(--button-weight);
  border-radius: var(--button-radius);
  box-shadow: var(--button-shadow);
  font-size: var(--text-lg);

  &:disabled {
    border-color: rgba(0, 0, 0, 0.15);
    background: var(--color-veryLightGrey);
    color: var(--color-mediumGrey);
  }
`;

const StyledPrimaryButton = styled(Action)`
  ${buttonCss};
  height: var(--s-12);
  padding: 0 var(--s-5);
  background: var(--button-bg-color);
  color: var(--button-text-color);
  border: var(--button-border);
  width: 100%;
`;

const StyledPrimarySmallButton = styled(Action)`
  ${buttonCss};
  height: var(--s-8);
  padding: 0 var(--s-4);
  background: var(--button-bg-color);
  color: var(--button-text-color);
  border: var(--button-border);
`;

const StyledSecondaryButton = styled(Action)`
  ${buttonCss};
  height: var(--s-12);
  padding: 0 var(--s-5);
  background: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-lightGrey);
`;

const StyledSecondarySmallButton = styled(Action)`
  ${buttonCss};
  height: var(--s-8);
  padding: 0 var(--s-4);
  background: var(--color-white);
  color: var(--color-primary);
  border: 1px solid var(--color-lightGrey);
`;

const StyledInvisibleButton = styled(Action)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: var(--s-8);
  padding: 0 var(--s-4);
  color: var(--color-primary);
  letter-spacing: var(--button-letter-spacing);
  font-weight: var(--button-weight);
`;

export const ButtonPrimary: React.FC<Action> = (props) => <StyledPrimaryButton {...props} />;

export const ButtonPrimarySmall: React.FC<Action> = (props) => <StyledPrimarySmallButton {...props} />;

export const ButtonSecondary: React.FC<Action> = (props) => <StyledSecondaryButton {...props} />;

export const ButtonSecondarySmall: React.FC<Action> = (props) => <StyledSecondarySmallButton {...props} />;

export const ButtonInvisible: React.FC<Action> = (props) => <StyledInvisibleButton {...props} />;

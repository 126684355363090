import styled, { css, StyledComponent } from 'styled-components';
import { ClientConfig } from 'src/core/ClientConfig';

export type Text = {
  as: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 'sm' | 'base' | 'lg' | 'xl';
  color?: keyof ClientConfig.Type['theme']['palette'];
  weight?: 'normal' | 'medium' | 'bold';
  className?: string;
};

export const Text: StyledComponent<'div', any, Text, never> = styled.div(
  ({ size, color, weight }: Text) => css`
    font-size: ${size ? `var(--text-${size})` : 'var(--text-base)'};
    line-height: ${size ? `var(--line-height-${size})` : 'var(--line-height-base)'};
    color: ${color ? `var(--color-${color})` : 'var(--color-darkGrey);'};
    font-weight: ${weight ? `var(--font-weight-${weight})` : `var(--font-weight-normal)`};
  `
);

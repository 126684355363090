import { DataCollector } from 'src/core/ClientConfig/DataCollector';
import { UnprocessedImage } from 'src/core/queries';
import { Photos, State } from 'src/hooks/types';

const photoDataCollectors: Array<DataCollector.Type['tag']> = [
  'VehiclePhotos',
  'VehicleSidePhotos',
  'AdditionalPhotos',
  'VinProof',
  'DamagePhotos',
  'PhotoReview',
];

const getPhotosKeyValueObj = (photos: Array<UnprocessedImage>) => {
  return photos.reduce((acc: Record<string, UnprocessedImage>, item: UnprocessedImage) => {
    const key: string = item.id;
    acc[key] = item;
    return acc;
  }, {});
};

// Collect other DCs photos saved in the Localstorage
const collectDcPhotoIds = (
  currentDcTag: string,
  currentDcPhotosIds: string[],
  state: State
): Array<string> => {
  const photoDcKeys = photoDataCollectors.filter((dc) => dc !== currentDcTag);
  // Todo: Fix type error as it is not safe at the moment
  const allInactiveDcPhotosIds = photoDcKeys.reduce((acc: Array<string>, dcTag) => {
    //@ts-ignore
    return [...acc, ...state.collectors[dcTag].photos];
  }, []);

  return [...allInactiveDcPhotosIds, ...currentDcPhotosIds];
};

type Props = {
  state: State;
  dc: DataCollector.Type;
  value: any;
};

export const useDataCollectorsPhoto = ({ state, dc, value }: Props) => {
  const isPhotoCollector = photoDataCollectors.includes(dc.tag);
  const currentDcPhotos: Array<UnprocessedImage> = value?.photos || [];
  const currentDcPhotoIds: Array<string> = currentDcPhotos?.map((photo: UnprocessedImage) => photo.id) || [];
  const allDcPhotoIds = collectDcPhotoIds(dc.tag, currentDcPhotoIds, state);
  const photosKeyValueObj: Photos = currentDcPhotos ? getPhotosKeyValueObj(currentDcPhotos) : {};
  const allPhotos: Photos = {
    ...removeDeletedPhotos(state.photos),
    ...(isPhotoCollector && photosKeyValueObj),
  };

  function removeDeletedPhotos(photos: Photos): Photos {
    const keys = Object.keys(photos).filter((key) => allDcPhotoIds.includes(key));
    const newPhotoObject = keys.reduce((acc: Record<string, UnprocessedImage>, key: string) => {
      acc[`${key}`] = state.photos[`${key}`];
      return acc;
    }, {});

    return newPhotoObject;
  }

  return {
    isPhotoCollector,
    currentDcPhotos,
    currentDcPhotoIds,
    allPhotos,
  };
};

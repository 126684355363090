import { useEffect, useState } from 'react';
import * as t from 'io-ts';

import { IO } from 'src/lib/IO';
import { Maybe } from 'src/lib/Maybe';
import { match } from 'src/lib/pattern';
import { noop } from 'src/lib/Function';
import { LocalStorageStore } from 'src/lib/Storage';
import { SessionId } from 'src/Routes';
import { Api, ClientImageId, Vin } from 'src/Api';
import { UnprocessedImage, UploadedImage, Stage } from 'src/core/queries';
import { PointOfImpact } from '../components/collectors/DamageLocation';
import { DataCollector } from 'src/core/ClientConfig/DataCollector';
import { PhotoDataCollectorState } from 'src/components/DataCollector';
import { Part } from 'src/core/ClientConfig/Part';
import { ClientId } from 'src/core/ClientConfig/ClientId';
import { AdditionalDetailsValue } from '../components/collectors/AdditionalDetails';
import { VehicleMileageValue } from '../components/collectors/VehicleMileage';
import { useDataCollectorsPhoto, Uploading, UploadReady, UploadComplete, usePolling } from 'src/hooks';

import { UseUploadImagesState } from 'src/hooks/types';
import { trackError } from './useTracking';

// Claim Satuses
type Submitted = {
  tag: 'Submitted';
  parts: Array<Part.Type>;
};
export const Submitted = (parts: Array<Part.Type>): JourneyStatus => ({
  tag: 'Submitted',
  parts,
});

type Submitting = { tag: 'Submitting' };
const Submitting: JourneyStatus = { tag: 'Submitting' };

export type Complete = { tag: 'Complete' };
export const Complete: JourneyStatus = { tag: 'Complete' };

type Ready = { tag: 'Ready' };
const Ready: JourneyStatus = { tag: 'Ready' };

type Init = { tag: 'Init' };
const Init: JourneyStatus = { tag: 'Init' };

export type JourneyStatus = Init | Ready | Submitting | Submitted | Complete;

export type Photos = Record<string, UnprocessedImage>;

export type State = {
  active: DataCollector.Type['tag'];
  sessionId: string;
  status: JourneyStatus;
  parts: Array<Part.Type>;
  photos: Photos;
  apiError: boolean;
  browserMemoryError: boolean;
  photosUploadStatus: UseUploadImagesState;
  collectors: {
    VehiclePhotos: PhotoDataCollectorState;
    VehicleSidePhotos: PhotoDataCollectorState;
    AdditionalPhotos: PhotoDataCollectorState;
    DamagePhotos: PhotoDataCollectorState;
    PhotoReview: PhotoDataCollectorState;
    VinProof: PhotoDataCollectorState;
    DamageLocation: Array<PointOfImpact>;
    AdditionalDetails: AdditionalDetailsValue;
    DamageReview: Array<Part.Type>;
    Vin: Vin;
    VehicleMileage: VehicleMileageValue;
  };
};

type CollectorsC = t.TypeOf<typeof CollectorsC>;
const CollectorsC = t.type({
  VehiclePhotos: t.type({ submitted: t.boolean, photos: t.array(t.string) }),
  VehicleSidePhotos: t.type({ submitted: t.boolean, photos: t.array(t.string) }),
  AdditionalPhotos: t.type({ submitted: t.boolean, photos: t.array(t.string) }),
  DamagePhotos: t.type({ submitted: t.boolean, photos: t.array(t.string) }),
  PhotoReview: t.type({ submitted: t.boolean, photos: t.array(t.string) }),
  VinProof: t.type({ submitted: t.boolean, photos: t.array(t.string) }),
  DamageReview: t.array(Part.Codec),
  Vin: t.type({
    make: t.string,
    model: t.string,
    year: t.union([t.number, t.string]),
    vin: t.string,
    vinEntryMode: t.boolean,
    submitted: t.boolean,
  }),
  AdditionalDetails: t.type({
    isDrivable: t.union([t.null, t.boolean]),
    windshieldShattered: t.union([t.null, t.boolean]),
    airbagsDeployed: t.union([t.null, t.boolean]),
    agreeUsingGreenParts: t.union([t.null, t.boolean]),
  }),
  VehicleMileage: t.type({
    mileage: t.union([t.null, t.number]),
    submitted: t.boolean,
  }),
  DamageLocation: t.array(
    t.union([
      t.literal('Front Center'),
      t.literal('Left Front Corner'),
      t.literal('Right Front Corner'),
      t.literal('Rear Center'),
      t.literal('Left Rear Corner'),
      t.literal('Right Rear Corner'),
      t.literal('Left Side'),
      t.literal('Right Side'),
      t.literal('Left Rear Side'),
      t.literal('Right Rear Side'),
      t.literal('Left Front Side'),
      t.literal('Right Front Side'),
    ])
  ),
});

type PhotosUploadStatusC = t.TypeOf<typeof PhotosUploadStatusC>;
const PhotosUploadStatusC = t.type({
  status: t.type({
    tag: t.union([t.literal('Complete'), t.literal('Ready'), t.literal('Uploading'), t.literal('Failed')]),
  }),
  uploaded: t.array(UploadedImage),
  failed: t.array(t.string),
});

export type LocalStorageState = t.TypeOf<typeof LocalStorageState>;
export const LocalStorageState = t.type({
  sessionId: t.string,
  active: t.union([
    t.literal('VehiclePhotos'),
    t.literal('VehicleSidePhotos'),
    t.literal('AdditionalPhotos'),
    t.literal('VinProof'),
    t.literal('DamagePhotos'),
    t.literal('PhotoReview'),
    t.literal('Vin'),
    t.literal('DamageLocation'),
    t.literal('AdditionalDetails'),
    t.literal('DamageReview'),
    t.literal('VehicleMileage'),
    t.literal('UploadPhotos'),
  ]),
  parts: t.array(Part.Codec),
  photos: t.record(t.string, UnprocessedImage),
  photosUploadStatus: PhotosUploadStatusC,
  collectors: CollectorsC,
});

const Store = new LocalStorageStore(LocalStorageState);
export const useAppState = (
  sessionId: SessionId,
  _clientId: ClientId.Type,
  dataCollectors: Array<DataCollector.Type>,
  api: Api,
  defaultValues: Partial<State['collectors']>
) => {
  const [state, setState] = useState<State>({
    active: dataCollectors[0].tag,
    status: Init,
    sessionId: sessionId,
    apiError: false,
    browserMemoryError: false,
    collectors: {
      VehiclePhotos: { submitted: false, photos: [] },
      VehicleSidePhotos: { submitted: false, photos: [] },
      AdditionalPhotos: { submitted: false, photos: [] },
      DamagePhotos: { submitted: false, photos: [] },
      PhotoReview: { submitted: false, photos: [] },
      VinProof: { submitted: false, photos: [] },
      DamageLocation: [],
      DamageReview: [],
      AdditionalDetails: {
        isDrivable: null,
        airbagsDeployed: null,
        windshieldShattered: null,
        agreeUsingGreenParts: null,
      },
      VehicleMileage: {
        submitted: false,
        mileage: defaultValues.VehicleMileage?.mileage || null,
      },
      Vin: {
        vin: '',
        make: '',
        model: '',
        year: '',
        vinEntryMode: true,
        submitted: false,
      },
    },
    parts: [],
    photos: {},
    photosUploadStatus: {
      status: UploadReady,
      uploaded: [],
      failed: [],
    },
  });

  const setJourneyStatus = (status: JourneyStatus): void =>
    save(
      match(status, {
        Submitted: ({ parts }) => ({
          status,
          parts,
        }),
        _: () => ({ status }),
      })
    );

  useEffect(() => {
    Store.get(sessionId).unsafeRunAsync(noop, (mStore) => {
      mStore.foldL(
        () =>
          Store.truncate(sessionId).unsafeRunAsync(
            () => setJourneyStatus(Ready),
            () => setJourneyStatus(Ready)
          ),
        (localStorageState) => {
          return localStorageState.sessionId === sessionId
            ? save(serialiseState(localStorageState))
            : Store.delete(sessionId).unsafeRunAsync(noop, () => setJourneyStatus(Ready));
        }
      );
    });
  }, []);

  const save = (newState: Partial<State>): void => {
    const showErrorModalCallback = () => {
      setState((state) => ({
        ...state,
        browserMemoryError: true,
      }));
    };

    setState((state) => {
      Store.set(sessionId, deserialise({ ...state, ...newState }), showErrorModalCallback).unsafeRunAsync(
        noop,
        noop
      );
      return {
        ...state,
        ...newState,
      };
    });
  };

  const isValid = dataCollectors
    .filter((dc) => dc.requiredForClassfication)
    .reduce(
      (acc, dc) =>
        match(dc, {
          AdditionalPhotos: (dc) => acc && state.collectors[dc.tag].submitted,
          VehiclePhotos: (dc) => acc && state.collectors[dc.tag].submitted,
          VehicleSidePhotos: (dc) => acc && state.collectors[dc.tag].submitted,
          PhotoReview: (dc) => acc && state.collectors[dc.tag].submitted,
          DamagePhotos: (dc) => acc && state.collectors[dc.tag].submitted,
          VinProof: (dc) => acc && state.collectors[dc.tag].submitted,
          DamageLocation: (dc) => acc && state.collectors[dc.tag].length > 0,
          DamageReview: (dc) => acc && state.collectors[dc.tag].length > 0,
          Vin: (dc) => acc && state.collectors[dc.tag].submitted,
          AdditionalDetails: (dc) =>
            acc &&
            Object.values(state.collectors[dc.tag]).reduce<boolean>(
              (acc, v) => acc && Maybe.fromNullable(v).fold(false, () => true),
              true
            ),
          VehicleMileage: (dc) =>
            acc &&
            Object.values(state.collectors[dc.tag]).reduce<boolean>(
              (acc, v) => acc && Maybe.fromNullable(v).fold(false, () => true),
              true
            ),
          // Data Collector Actions
          UploadPhotos: () => acc && true,
        }),
      true
    );

  // Stages in which the claim can be considered completed
  const stages: Array<Stage> = ['POLICYHOLDER_INPUT', 'AI_IMAGE_CLASSIFICATION', 'AI_DAMAGED_PARTS_OUTPUT'];

  const completeClaim = (sessionId: SessionId) => {
    return api
      .getClaim(sessionId)
      .flatMap((claim) =>
        stages.includes(claim.stage) && claim.status !== 'DONE' ? api.completeClaim(sessionId) : IO.unit
      )
      .unsafeRunAsync(
        (error) => {
          trackError({ name: 'Complete claim error' }, error);

          setJourneyStatus(Complete);
        },
        () => setJourneyStatus(Complete)
      );
  };

  const { startPolling, stopPolling } = usePolling(async () => {
    if (state.photosUploadStatus.status.tag === UploadComplete.tag) {
      const type = dataCollectors.find((dc) => dc.tag === 'DamageReview') ? 'damagedParts' : 'detectedParts';
      const claim = await api
        .getClaim(sessionId)
        .toPromise((e) => trackError({ name: 'Get Claim polling error' }, e));

      // Flexible API remapped some statuses from PROCESSED_* to DONE
      // As we use estimating API and Flexible API, both have to be covered
      const doneStatuses = ['PROCESSED_IMAGE_RESULTS', 'DONE'];
      const isAiImageClassificationCompleted =
        claim.stage === 'AI_IMAGE_CLASSIFICATION' && doneStatuses.includes(claim.status);
      const isPolicyholderInputDone = claim.stage === 'POLICYHOLDER_INPUT' && claim.status === 'DONE';

      if (isPolicyholderInputDone || claim[type].length > 0) {
        const parts = type === 'detectedParts' ? claim.detectedParts : claim.damagedParts.map((p) => p.id);
        setJourneyStatus(Submitted(parts));
        stopPolling();
        return;
      }

      if (claim.stage === 'POLICYHOLDER_INPUT' && claim.images.length > 0) {
        return await api
          .triggerEndpoint(sessionId, 'imageResults')
          .toPromise((e) => trackError({ name: 'Image result trigger error' }, e));
      }

      if (isAiImageClassificationCompleted) {
        return await api
          .triggerEndpoint(sessionId, 'damagedParts')
          .toPromise((e) => trackError({ name: 'Damaged parts trigger error' }, e));
      }
    }
  }, 4000);

  useEffect(() => {
    const { parts, status, photosUploadStatus, photos } = state;
    const claimSubmitted =
      Object.keys(photos).length === 0 && (status.tag === 'Submitting' || status.tag === 'Submitted');
    const doNotSubmitClaim = !isValid || parts.length > 0 || claimSubmitted;

    if (doNotSubmitClaim) {
      return;
    }

    if (photosUploadStatus.status.tag === UploadReady.tag) {
      setState((state) => ({
        ...state,
        photosUploadStatus: {
          ...state.photosUploadStatus,
          status: Uploading,
        },
      }));
    }

    const uploadPhotosDc = dataCollectors.find((dc) => dc.tag === 'UploadPhotos');
    setJourneyStatus(Submitting);

    if (state.photosUploadStatus.status.tag === UploadComplete.tag && uploadPhotosDc) {
      api
        .getClaim(sessionId)
        .flatMap((claim) =>
          claim.stage === 'POLICYHOLDER_INPUT' && claim.images.length === 0
            ? api.updateClaim(sessionId, state.collectors, state.photosUploadStatus.uploaded)
            : IO.unit
        )
        .tap(startPolling)
        .unsafeRunAsync((error) => {
          if (state.active === 'UploadPhotos') {
            // Moves to the next data collector and show the modal there
            saveDcValue(uploadPhotosDc, {});
          }
          save({ apiError: true });

          const errorData = {
            name: 'Update Claim API error',
            description: 'Error occured after photo upload step',
          };

          trackError(errorData, error);
        }, noop);
    }
  }, [state.parts, isValid, state.photosUploadStatus.status]);

  useEffect(() => {
    if (state.photosUploadStatus.status.tag === UploadComplete.tag) {
      // Clean base64 string from the object to release memory
      const cleanPhotos: Photos = {};
      Object.keys(state.photos).forEach((key) => {
        cleanPhotos[key] = { ...state.photos[key], base64: '' };
      });

      save({ photos: cleanPhotos });
    }
  }, [state.photosUploadStatus.status.tag]);

  const setActive = (dataCollector: DataCollector.Type): void => {
    const index = dataCollectors.findIndex((dc) => dc.tag === dataCollector.tag);
    save({ active: dataCollectors[index].tag });
  };

  const getNewState = <T extends DataCollector.Type, V>(dc: T, value: V, setNextActiveStep: boolean) => {
    const index = dataCollectors.findIndex((d) => d.tag === dc.tag) + 1;
    const { isPhotoCollector, currentDcPhotoIds, allPhotos } = useDataCollectorsPhoto({
      state,
      dc,
      value,
    });

    return isPhotoCollector
      ? {
          ...state,
          ...(setNextActiveStep ? { active: dataCollectors[index].tag } : {}),
          collectors: {
            ...state.collectors,
            [dc.tag]: {
              ...value,
              photos: currentDcPhotoIds,
            },
          },
          photos: allPhotos,
        }
      : {
          ...state,
          ...(setNextActiveStep ? { active: dataCollectors[index].tag } : {}),
          collectors: {
            ...state.collectors,
            [dc.tag]: value,
          },
        };
  };

  const saveDcValue = <T extends DataCollector.Type, V>(dc: T, value: V, setNextActiveStep?: boolean) => {
    const newState = getNewState(dc, value, setNextActiveStep ?? true);
    save(newState);
  };

  const complete = <T extends DataCollector.Type, V>(dc: T, value: V) => {
    const newState = getNewState(dc, value, false);

    Store.set(sessionId, deserialise(newState))
      .flatMap(() =>
        api
          .getClaim(sessionId)
          .flatMap(() =>
            api.updateAdditionalDetails(sessionId, newState.collectors, state.photosUploadStatus.uploaded)
          )
          .flatMap(() => api.completeClaim(sessionId))
      )
      .unsafeRunAsync(
        (error) => {
          save({ apiError: true });

          const errorData = {
            name: 'Complete Claim API error',
            description: 'Error occured while trying to update additional details and complete claim',
          };

          trackError(errorData, error);
        },
        () => {
          stopPolling();
          completeClaim(sessionId);
        }
      );

    save(newState);
  };

  const deserialise = (state: State): LocalStorageState => ({
    active: state.active,
    collectors: state.collectors,
    sessionId,
    parts: state.parts,
    photos: state.photos,
    photosUploadStatus: state.photosUploadStatus,
  });

  const serialiseState = (state: LocalStorageState): State => ({
    status: state.parts.length > 0 ? Submitted(state.parts) : Ready,
    active: state.active,
    collectors: state.collectors,
    sessionId: state.sessionId,
    parts: state.parts,
    apiError: false,
    browserMemoryError: false,
    photos: state.photos,
    photosUploadStatus: {
      ...state.photosUploadStatus,
      uploaded: state.photosUploadStatus.uploaded.map((item) => ({
        ...item,
        clientImageId: ClientImageId(item.clientImageId),
      })),
    },
  });

  return {
    state,
    setState,
    setActive,
    saveDcValue,
    complete,
    save,
    setJourneyStatus,
  };
};

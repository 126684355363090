import NextLink from 'next/link';
import { Route } from 'src/Routes';
import { match } from 'src/lib/pattern';
import { Spinner } from '../Spinner';
import styled from 'styled-components';
import { Translation } from '../Translation';
import { TranslationStrings } from 'src/core/Translation';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = React.ButtonHTMLAttributes<HTMLAnchorElement>;

export type Button = ButtonProps & {
  tag: 'Button';
  loading?: boolean;
  uploadingText?: keyof TranslationStrings;
  disabled?: boolean;
};

export type Link = AnchorProps & {
  tag: 'Link';
  href: Route;
};

export type ExternalLink = AnchorProps & {
  tag: 'ExternalLink';
  href: string;
};

export type Action = Button | Link | ExternalLink;

export const Action = (props: Action): JSX.Element =>
  match(props, {
    Button: ({ tag, loading, uploadingText, children, disabled, ...rest }) => (
      <ButtonWithDataLoading data-loading={loading} {...rest} disabled={disabled || loading} tag={tag}>
        {loading ? (
          <>
            <StyledSpinner />
            {uploadingText && (
              <StyledTranslation>
                <Translation id={uploadingText} />
              </StyledTranslation>
            )}
          </>
        ) : (
          children
        )}
      </ButtonWithDataLoading>
    ),
    Link: ({ tag, href, children, ...rest }) => (
      <NextLink passHref href={href}>
        <a {...rest}>{children}</a>
      </NextLink>
    ),
    ExternalLink: ({ tag, children, ...props }) => (
      <a target="_blank" rel="noopener" {...props}>
        {children}
      </a>
    ),
  });

export type ButtonWithDataLoading = Button & {
  'data-loading'?: boolean;
};

const ButtonWithDataLoading = styled.button<ButtonWithDataLoading>`
  cursor: 'pointer';
  [data-loading='true']&:disabled {
    background: ${(props) => {
      return props['data-loading'] ? 'var(--color-primary)' : 'auto';
    }};
  }
`;
const StyledSpinner = styled(Spinner)`
  width: 16px;
  color: var(--color-white);
`;

const StyledTranslation = styled.div`
  margin-left: var(--s-2);
`;

export type OnlineType = { tag: 'Online' };
export const Online: ConnectionStatus = { tag: 'Online' };

export type OfflineType = { tag: 'Offline' };
export const Offline: ConnectionStatus = { tag: 'Offline' };

export type ConnectionStatus = OfflineType | OnlineType;

export const useConnectionCheck = () => {
  const getConnectionStatus = () => {
    const isBrowser = typeof window !== 'undefined';
    const status: ConnectionStatus = isBrowser && navigator?.onLine ? Online : Offline;
    return status;
  };

  return {
    isOnline: () => getConnectionStatus().tag === Online.tag,
    getConnectionStatus,
  };
};

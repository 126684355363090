import { Text } from 'src/components/primitives/Text';

type LabelProps = {
  htmlFor: string;
};

export const Label: React.FC<LabelProps> = ({ htmlFor, children }) => {
  return (
    <label htmlFor={htmlFor} style={{ marginBottom: '4px' }}>
      <Text as="span" size="lg">
        {children}
      </Text>
    </label>
  );
};

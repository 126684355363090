const getBase64FromImage = async (file: Blob): Promise<string | undefined> => {
  var reader = new FileReader();
  reader.readAsDataURL(file as Blob);

  return new Promise((reslove, reject) => {
    reader.onload = () => reslove(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const getImageFromBase64 = (base64: string, fileName: string) => {
  const arr: string[] = base64.split(',');
  // The mime type on base64 string should never fallback on empty string
  const mime: string = arr[0]?.match(/:(.*?);/) ? arr[0]?.match(/:(.*?);/)![1] : '';

  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export { getBase64FromImage, getImageFromBase64 };

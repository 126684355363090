import React from 'react';
import styled, { css } from 'styled-components';
import { match } from 'src/lib/pattern';
import { Text } from 'src/components/primitives/Text';
import { Position, TopRight, TopLeft, BottomLeft, BottomRight } from './DropzoneCarTypes';

type Image = {
  src: string;
  NumberPosition: Position;
  size: {
    width: string;
    height: string;
  };
};

export const dropzoneCarSidePreviewImages = [
  {
    src: '/assets/app/1-front.png',
    NumberPosition: TopLeft,
    size: {
      width: '178',
      height: '128',
    },
  },
  {
    src: '/assets/app/2-side.png',
    NumberPosition: TopRight,
    size: {
      width: '402',
      height: '138',
    },
  },
  {
    src: '/assets/app/3-rear.png',
    NumberPosition: BottomLeft,
    size: {
      width: '179',
      height: '128',
    },
  },
  {
    src: '/assets/app/4-side.png',
    NumberPosition: BottomRight,
    size: {
      width: '402',
      height: '138',
    },
  },
];

export const DropzoneCarSidePreview = (): JSX.Element => (
  <Container>
    {dropzoneCarSidePreviewImages.map((image: Image, index) => (
      <Box key={`${image}+${index}`}>
        <img src={image.src} width={image.size.width} height={image.size.height} />
        <Number position={image.NumberPosition}>
          <Text as="span" size="lg" weight="medium" color="primary">
            {index + 1}
          </Text>
        </Number>
      </Box>
    ))}
    <VerticleLine />
    <HorizontalLine />
  </Container>
);

const Box = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--s-8) var(--s-3);
`;

const Number = styled.div<{ position: Position }>`
position: absolute;
${({ position }) =>
  match(position, {
    TopLeft: () => css`
      top: var(--s-1);
      left: var(--s-2);
    `,
    TopRight: () => css`
      top: var(--s-1);
      left: var(--s-2);
    `,
    BottomLeft: () => css`
      bottom: var(--s-1);
      left: var(--s-2);
    `,
    BottomRight: () => css`
      bottom: var(--s-1);
      right: var(--s-2);
    `,
  })}
}
`;

const Container = styled.div`
  border: 1px solid var(--color-lightGrey);
  border-radius: var(--s-2);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 70%;
  position: relative;
`;

const VerticleLine = styled.div`
  background: var(--color-lightGrey);
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30%;
`;

const HorizontalLine = styled.div`
  background: var(--color-lightGrey);
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
`;

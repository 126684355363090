import Resizer from 'react-image-file-resizer';

export type ReformatImage = string | Blob | ProgressEvent<FileReader>;
const reformatImage = (imageFile: File, orientation: any): Promise<ReformatImage> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      imageFile,
      1200,
      1200,
      'JPEG',
      95,
      orientation,
      (rimage) => resolve(rimage),
      'blob'
    );
  });
};

export { reformatImage };

import styled from 'styled-components';
import { CircleWarning } from './primitives/Icons';
import { Text } from './primitives/Text';

export const InputErrorMessage: React.FC = ({ children }) => (
  <Container>
    <CircleWarningIcon />
    <Text as="p" size="base" color="negative">
      {children}
    </Text>
  </Container>
);

const Container = styled.div`
  display: flex;
  margin-top: var(--s-2);
  color: var(--color-negative);
`;

const CircleWarningIcon = styled(CircleWarning)`
  color: var(--color-negative);
  margin-right: var(--s-1);
  margin-top: var(--s-1);
  width: 14px;
  height: 14px;
`;

import { ExifParserFactory } from 'ts-exif-parser';

export type GetMetaData = {
  originalCreationTimestamp: string;
  metadata: string;
};

export function getMetadata(imageFile: File): Promise<GetMetaData> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(imageFile);
    reader.onload = () => {
      const arrayBuffer = reader.result;
      const data = ExifParserFactory.create(arrayBuffer as ArrayBuffer).parse();

      const datatimeOriginal = data?.tags?.DateTimeOriginal;
      const createDate = data?.tags?.CreateDate;

      const date =
        datatimeOriginal && createDate
          ? Math.min(datatimeOriginal, createDate) * 1000
          : datatimeOriginal || createDate;

      return resolve({
        originalCreationTimestamp: new Date(date || Date.now()).toUTCString(),
        metadata: JSON.stringify(data),
      });
    };
  });
}

import { createGlobalStyle } from 'styled-components';

export const GlobalAppStyles = createGlobalStyle`
  html { height: 100%; }

  body {
    min-height: 100%;
    display: flex;
    font-family: var(--font-family-body);
    font-size: var(--text-base);
    line-height: var(--line-height-base);
    color: var(--color-darkGrey);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #__next {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  /* To override Google Maps styles */
  .pac-container {
    top: 110px !important;
    margin: 0 auto !important;
    left: 0 !important;
    width: 90% !important;
    right: 0 !important;
  }

  .pac-container::after {
    background-image: none !important;
  }
`;

import * as t from 'io-ts';
import { IO, UIO } from 'src/lib/IO';
import { updateImage } from 'src/lib/ImageProcessing/updateImages';
import { DecodeData } from 'src/lib/DecodeData';
import { andThrow, noop } from 'src/lib/Function';
import { Route, Routes, SessionId } from 'src/Routes';
import { curryMatch } from 'src/lib/pattern';
import { Claim, GetImageUploadInfo, ProcessedImage, UploadedImage } from 'src/core/queries';
import { PointOfImpact } from './components/collectors/DamageLocation';
import { Part } from './core/ClientConfig/Part';
import { AdditionalDetailsValue } from './components/collectors/AdditionalDetails';
import { PhotoDataCollectorState } from 'src/components/DataCollector';
import { VehicleMileageValue } from 'src/components/collectors/VehicleMileage';
import { Nominal } from './lib/Nominal';
import { appClient as client } from './clients/base/appClient';
import { PublicClaim } from './services/claimService';
import { Vin } from './components/collectors/Vin/Vin';
import { fetchWithRetry, fetchS3WithRetry } from 'src/utils/fetchWithRetry';

export type Vin = t.TypeOf<typeof VinC>;
const VinC = t.type({
  make: t.string,
  model: t.string,
  year: t.union([t.number, t.string]),
  vin: t.string,
  vinEntryMode: t.boolean,
  submitted: t.boolean,
});

export type Collectors = {
  VehicleSidePhotos: PhotoDataCollectorState;
  VehiclePhotos: PhotoDataCollectorState;
  AdditionalPhotos: PhotoDataCollectorState;
  VinProof: PhotoDataCollectorState;
  DamagePhotos: PhotoDataCollectorState;
  PhotoReview: PhotoDataCollectorState;
  AdditionalDetails: AdditionalDetailsValue;
  DamageLocation: Array<PointOfImpact>;
  DamageReview: Array<Part.Type> | null;
  Vin: Vin;
  VehicleMileage: VehicleMileageValue;
};

export type CompletedImage = ProcessedImage & { clientImageId: ClientImageId };

export type ClientImageId = Nominal<string, { readonly ClientImageId: unique symbol }>;
export const ClientImageId = Nominal<ClientImageId>();

interface ClaimsService {
  uploadImage: (
    category: Category,
    clientImageId: ClientImageId,
    sessionId: SessionId,
    file: File
  ) => IO<CompletedImage, FailedUpload>;
  updateClaim: (
    sessionId: SessionId,
    collectors: Collectors,
    photos: Array<UploadedImage>
  ) => IO<void, FetchError>;
  updateAdditionalDetails: (
    sessionId: SessionId,
    collectors: Collectors,
    photos: Array<UploadedImage>
  ) => IO<void, FetchError>;
  getClaim: (sessionId: SessionId) => IO<Claim, FetchError>;
  sendFeedBack: (sessionId: SessionId, rating: number) => IO<void, FetchError>;
}

export type Category = 'vp' | 'ap' | 'pr' | 'dp' | 'vin';
export class FailedUpload extends Error {
  tag = 'FailedUpload' as 'FailedUpload';
}

export type FetchError = FatalError | ConnectionError;
export class Api implements ClaimsService {
  constructor() {}

  private getImageUploadInfo = (
    sessionId: SessionId,
    id: string,
    contentType: string
  ): IO<GetImageUploadInfoSuccess, FetchError> =>
    FetchApi({
      route: Routes.api.getImageUploadInfo,
      codec: GetImageUploadInfoResult,
      body: { sessionId, id, contentType },
    }).flatMap(
      curryMatch({
        Success: IO.pure,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  private getUploadedImageInfo = (
    sessionId: SessionId,
    key: string
  ): IO<GetUploadedImageInfoSuccess, FetchError> =>
    FetchApi({
      route: Routes.api.getUploadedImageInfo,
      codec: GetUploadedImageInfoResult,
      body: { sessionId, key },
    }).flatMap(
      curryMatch({
        Success: IO.pure,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  private uploadToFileService = (path: string, body: Blob): IO<void, FetchError> =>
    IO.fromPromise(() =>
      fetchS3WithRetry(path, {
        method: 'PUT',
        headers: {
          'Content-Type': body.type,
        },
        body,
      })
    )
      .mapFailure<ConnectionError>((error) => ({
        tag: 'ConnectionError',
        message: error.message,
      }))
      .filterOrFail((res) => res.ok === true, new FatalError('Image service responded with an error'))
      .map(noop);

  private getVehicleInformation = (vehicleInformation: Vin) => {
    // This condition guaranties backward compatibility
    // It basically cover the case where the Vin collector is not in the client flow
    if (vehicleInformation.vinEntryMode) {
      if (vehicleInformation.vin.length === 0) {
        return undefined;
      } else {
        return { vin: vehicleInformation.vin };
      }
    } else {
      return {
        make: vehicleInformation.make,
        model: vehicleInformation.model,
        year: vehicleInformation.year,
      };
    }
  };

  public uploadImage = (
    category: Category,
    clientImageId: ClientImageId,
    sessionId: SessionId,
    file: File
  ): IO<CompletedImage, FailedUpload> =>
    IO.fromPromise(() => updateImage(file, `${category}-${sessionId}`))
      .flatMapPair((image) => this.getImageUploadInfo(sessionId, image.id, (image.rimage as Blob).type))
      .flatMap(([updatedImage, { data }]) =>
        this.uploadToFileService(data.path, updatedImage.rimage as Blob).map(() => data.key)
      )
      .flatMap((key) =>
        this.getUploadedImageInfo(sessionId, key).map(({ data }) => {
          return {
            ...data,
            clientImageId,
          };
        })
      )
      .mapFailure(() => new FailedUpload());

  public triggerEndpoint = (
    sessionId: SessionId,
    type: 'imageResults' | 'damagedParts'
  ): IO<void, FetchError> =>
    FetchApi({
      method: 'POST',
      route: Routes.api.triggerEndpoint,
      codec: TriggerEndpointResult,
      body: { sessionId, type },
    }).flatMap(
      curryMatch({
        Success: () => IO.unit,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  public getClaim = (sessionId: SessionId): IO<Claim, FetchError> =>
    FetchApi({
      method: 'POST',
      route: Routes.api.getClaim,
      codec: GetClaimResult,
      body: { sessionId },
    }).flatMap(
      curryMatch({
        Success: ({ data }) => IO.pure(data),
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  public updateClaim = (
    sessionId: SessionId,
    collectors: Collectors,
    photos: Array<UploadedImage>
  ): IO<void, FetchError> =>
    FetchApi({
      route: Routes.api.updateClaim,
      codec: UpdateClaimResult,
      body: {
        updateType: 'UPDATE_CLAIM',
        sessionId,
        images: photos,
        vin: this.getVehicleInformation(collectors.Vin),
        yesNo: getYesNo(collectors),
        mileage: collectors.VehicleMileage.mileage || undefined,
        ...(collectors.DamageLocation.length > 0 && { pointsOfImpact: collectors.DamageLocation }),
      },
    }).flatMap(
      curryMatch({
        Success: () => IO.unit,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  public updateAdditionalDetails = (
    sessionId: SessionId,
    collectors: Collectors,
    photos: Array<UploadedImage>
  ): IO<void, FetchError> => {
    return FetchApi({
      route: Routes.api.updateClaim,
      codec: UpdateClaimResult,
      body: {
        updateType: 'UPDATE_ADDITIONAL_DETAILS',
        sessionId,
        images: photos,
        vin: this.getVehicleInformation(collectors.Vin),
        yesNo: getYesNo(collectors),
        mileage: collectors.VehicleMileage.mileage || undefined,
        userVerifiedDamagedParts: collectors.DamageReview,
      },
    }).flatMap(
      curryMatch({
        Success: () => IO.unit,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );
  };

  public completeClaim = (sessionId: SessionId): IO<void, FetchError> =>
    FetchApi({
      route: Routes.api.updateClaim,
      codec: UpdateClaimResult,
      body: {
        updateType: 'COMPLETE_CLAIM',
        sessionId,
        stage: 'POLICYHOLDER_INPUT',
        status: 'DONE',
      },
    }).flatMap(
      curryMatch({
        Success: () => IO.unit,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  public sendFeedBack = (sessionId: SessionId, rating: number): IO<void, FetchError> =>
    FetchApi({
      route: Routes.api.sendFeedback,
      codec: SendFeedBackResult,
      body: { sessionId, rating },
    }).flatMap(
      curryMatch({
        Success: () => IO.unit,
        ConnectionError: IO.fail,
        Error: ({ message }) => IO.fail(new FatalError(message)),
      })
    );

  public postLinkOpened = (sessionId: string): Promise<PublicClaim | never> =>
    request({ route: Routes.api.postLinkOpened(sessionId), method: 'POST' });

  public getSessionClaim = (sessionId: string): Promise<PublicClaim | never> =>
    request({ route: Routes.api.getSessionClaim(sessionId), method: 'GET' });
}

const getYesNo = (
  collectors: Collectors
): Array<{
  questionId: string;
  questionText: string;
  respondedYes: boolean;
}> => {
  const data: Array<[boolean | null, string]> = [
    [collectors.AdditionalDetails.isDrivable, 'is-drivable'],
    [collectors.AdditionalDetails.windshieldShattered, 'windshield-shattered'],
    [collectors.AdditionalDetails.airbagsDeployed, 'airbags-deployed'],
    [collectors.AdditionalDetails.agreeUsingGreenParts, 'agree-using-green-parts'],
  ];
  return data
    .filter((d): d is [boolean, string] => typeof d[0] === 'boolean')
    .map(([respondedYes, questionId]) => ({
      questionId,
      questionText: '',
      respondedYes,
    }));
};

const ApiErrorResponse = t.type({
  tag: t.literal('Error'),
  message: t.string,
});

type GetImageUploadInfoSuccess = t.TypeOf<typeof GetImageUploadInfoSuccess>;
const GetImageUploadInfoSuccess = t.type({
  tag: t.literal('Success'),
  data: GetImageUploadInfo,
});

const GetImageUploadInfoResult = t.union([GetImageUploadInfoSuccess, ApiErrorResponse]);

type GetUploadedImageInfoSuccess = t.TypeOf<typeof GetUploadedImageInfoSuccess>;
const GetUploadedImageInfoSuccess = t.type({
  tag: t.literal('Success'),
  data: ProcessedImage,
});

const GetUploadedImageInfoResult = t.union([GetUploadedImageInfoSuccess, ApiErrorResponse]);

const UpdateClaimSuccess = t.type({
  tag: t.literal('Success'),
  data: t.null,
});

const UpdateClaimResult = t.union([UpdateClaimSuccess, ApiErrorResponse]);

const GetClaim = t.type({
  tag: t.literal('Success'),
  data: Claim,
});

const GetClaimResult = t.union([GetClaim, ApiErrorResponse]);

const TriggerEndpoint = t.type({
  tag: t.literal('Success'),
  data: t.null,
});

const TriggerEndpointResult = t.union([TriggerEndpoint, ApiErrorResponse]);

const SendFeedBack = t.type({
  tag: t.literal('Success'),
  data: t.null,
});

const SendFeedBackResult = t.union([SendFeedBack, ApiErrorResponse]);

const request = async <R>({
  route,
  method,
  body,
  contentType = 'application/json',
}: {
  route: Route;
  method: 'POST' | 'GET' | 'PUT' | 'DELETE';
  body?: object;
  contentType?: string;
}): Promise<R | never> => {
  try {
    const { data } = await client.request<R>({
      url: route,
      method,
      headers: {
        'Content-Type': contentType,
      },
      withCredentials: true, // Ensure cookies are passed through (this is safe as we only call our own API with this)
      data: body,
    });

    return data;
  } catch (error) {
    console.error(`Request to ${method} ${route} failed.`, error);
    throw error;
  }
};

const FetchApi = <C, B>({
  route,
  method = 'POST',
  codec,
  body,
  contentType = 'application/json',
}: {
  route: Route;
  codec: t.Type<C>;
  method?: 'POST' | 'GET' | 'PUT';
  body?: B;
  contentType?: string;
}): UIO<C | ConnectionErrorC> =>
  IO.fromPromise(() =>
    fetchWithRetry(route, {
      method,
      headers: {
        'Content-Type': contentType,
      },
      credentials: 'same-origin',
      body: body && JSON.stringify(body),
    })
      .then(async (res) => {
        const body = await res.json();

        if (body.status >= 400) {
          throw new Error(`Failed request with status ${res.status} and body ${JSON.stringify(body)}`);
        }

        return body;
      })
      .catch((error: Error) => {
        return {
          tag: 'ConnectionError',
          message: error.message,
        };
      })
  )
    .flatMap(DecodeData(t.union([codec, ConnectionErrorC]).decode))
    .handleFailure((e) => {
      return andThrow('Failed to decode api response')(e);
    });

type ConnectionError = { tag: 'ConnectionError'; message: string };

export class FatalError extends Error {
  tag = 'FatalError' as 'FatalError';
}

type ConnectionErrorC = t.TypeOf<typeof ConnectionErrorC>;
const ConnectionErrorC = t.type({ tag: t.literal('ConnectionError'), message: t.string });

import React from 'react';
import styled from 'styled-components';
import { Text } from 'src/components/primitives/Text';

export const vehiclePhotoPreviewImages = [
  '/assets/app/front-right.jpg',
  '/assets/app/front-left.jpg',
  '/assets/app/rear-right.jpg',
  '/assets/app/rear-left.jpg',
];

export const VehiclePhotoPreview = (): JSX.Element => (
  <Container>
    {vehiclePhotoPreviewImages.map((image, index) => (
      <Box key={`${image}+${index}`}>
        <img src={image} width={160} height={160} />
        <Number>
          <Text as="span" size="sm" weight="medium" color="secondary">
            {index + 1}
          </Text>
        </Number>
      </Box>
    ))}
  </Container>
);

const Box = styled.div`
  position: relative;
  border-right: 1px solid var(--color-lightGrey);
  &:last-child {
    border: none;
  }
`;

const Number = styled.div`
  position: absolute;
  top: 0;
  left: var(--s-2);
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

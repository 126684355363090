type TopLeft = {
  tag: 'TopLeft';
};
export const TopLeft: Position = {
  tag: 'TopLeft',
};

type TopRight = {
  tag: 'TopRight';
};

export const TopRight: Position = {
  tag: 'TopRight',
};

type BottomLeft = {
  tag: 'BottomLeft';
};

export const BottomLeft: Position = {
  tag: 'BottomLeft',
};

type BottomRight = {
  tag: 'BottomRight';
};

export const BottomRight: Position = {
  tag: 'BottomRight',
};

export type Position = TopLeft | TopRight | BottomLeft | BottomRight;

import { v4 } from 'uuid';
import { GetMetaData, getMetadata } from './getMetadata';
import { getOrientation } from './getOrientation';
import { reformatImage, ReformatImage } from './reformatImage';

function browserImageRotationSupport() {
  const bodyStyles: any = getComputedStyle(document.body);
  return bodyStyles['imageOrientation'] === 'from-image';
}

export type UpdatedImage = {
  id: string;
  metadata: GetMetaData;
  rimage: ReformatImage;
};

export const updateImage = async (image: File, tag: string): Promise<UpdatedImage> => {
  const names = image.name.split('.');
  const ext = names[names.length - 1];
  const orientation = await getOrientation(image);
  const metadata = await getMetadata(image);
  const rimage = await reformatImage(image, browserImageRotationSupport() ? 0 : orientation);

  return {
    id: `${tag}-${v4()}.${ext}`,
    metadata,
    rimage,
  };
};

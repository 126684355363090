import { useEffect, useState } from 'react';
import { getInitialBodyshopState } from 'src/store/bodyshop/context';
import { BodyshopState } from 'src/store/bodyshop/types';

export const useBodyshopState = () => {
  const [bodyshopState, setBodyshopState] = useState<BodyshopState | null>(null);

  useEffect(() => {
    const initialState = getInitialBodyshopState();
    setBodyshopState(initialState);
  }, []);

  return {
    bodyshopState,
    setBodyshopState,
  };
};

import React from 'react';
import { v4 } from 'uuid';
import { updateImage } from 'src/lib/ImageProcessing/updateImages';
import { UnprocessedImage } from 'src/core/queries';
import { Max, Min } from 'src/core/ClientConfig/DataCollector';
import { getBase64FromImage } from 'src/lib/ImageProcessing/base64Coverter';
import { ClientId } from 'src/core/ClientConfig/ClientId';
import { Category, ClientImageId } from 'src/Api';
import { Photos } from 'src/hooks/types';
import { SessionId } from 'src/Routes';

export enum ImageNamespace {
  AdditionalPhotos = 'additionalPhotos',
  DamagePhotos = 'damagePhotos',
  PhotoReview = 'photoReview',
  VehiclePhotos = 'vehiclePhotos',
  VehicleSidePhotos = 'vehicleSidePhotos',
  VinProof = 'vinProof',
}

type Valid = { tag: 'Valid' };
const Valid: Status = { tag: 'Valid' };

type Invalid = { tag: 'Invalid' };
const Invalid: Status = { tag: 'Invalid' };

type Complete = { tag: 'Complete' };
const Complete: Status = { tag: 'Complete' };

type Status = Valid | Invalid | Complete;

type Settings = {
  initialValue: Array<string>;
  min: Min;
  max: Max;
  namespace: ImageNamespace;
  clientId: ClientId.Type;
  photos: Photos;
  sessionId: SessionId;
  photoDuplicatesCheck?: boolean;
};

type UseAddImage = {
  items: UnprocessedImage[];
  addImages: (files: Array<File>, category: Category) => void;
  removeImage: (id: string) => void;
  status: Status;
  duplicatesError: boolean;
};

const getInitialValueItems = (initialValue: string[], photos: Photos) =>
  initialValue.map((value) => ({ ...photos[`${value}`] }));

const getStoredPhotos = (photos: Photos) => Object.keys(photos).map((value) => ({ ...photos[`${value}`] }));

export const useAddImage = ({
  min,
  max,
  initialValue,
  namespace,
  photos,
  sessionId,
  photoDuplicatesCheck,
}: Settings): UseAddImage => {
  const [items, setItems] = React.useState<Array<any>>(getInitialValueItems(initialValue, photos));
  const [storedItems, setStoredItems] = React.useState<Array<any>>(getStoredPhotos(photos));
  const [duplicatesError, setDuplicatesError] = React.useState(false);
  const status: Status =
    items.length < max && items.length >= min ? Valid : items.length == max ? Complete : Invalid;

  const addImages = (files: Array<File>, category: Category): void => {
    files.forEach(async (file, index) => {
      const id = v4();
      const processedImage = await updateImage(file, `${category}-${sessionId}`);
      const base64 = await getBase64FromImage(processedImage.rimage as Blob);
      const image: UnprocessedImage = {
        id,
        category,
        base64: base64 || '',
        clientImageId: ClientImageId(`${namespace}-${id}`),
        metadata: processedImage.metadata,
      };

      if (items.length + index < max) {
        let duplicates = 0;
        setDuplicatesError(false);

        if (!!photoDuplicatesCheck) {
          storedItems.map((photo) => {
            if (photo.base64 == image.base64) duplicates++;
          });

          items.map((item) => {
            if (item.base64 == image.base64) duplicates++;
          });
        }

        setDuplicatesError(!!duplicates);
        !duplicates && setItems((items) => [...items, image]);
      }
    });
  };

  const removeImage = (id: string): void => {
    setDuplicatesError(false);
    setStoredItems((items) => items.filter((item) => item.id !== id));
    return setItems((items) => items.filter((item) => item.id !== id));
  };

  return {
    items,
    status,
    addImages,
    removeImage,
    duplicatesError,
  };
};

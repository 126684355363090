import originalFetch from 'isomorphic-fetch';
import fetchBuilder from 'fetch-retry-ts';

// Retry on specific error codes
export const fetchWithRetry = fetchBuilder(originalFetch, {
  retries: 3,
  retryDelay: 500,
  retryOn: [400, 419, 409, 500, 503, 504],
});

// Retry only on Network Failure
export const fetchS3WithRetry = fetchBuilder(originalFetch, {
  retries: 3,
  retryDelay: 300,
  retryOn: [],
});

/* Method to validate VINs, Vehicle Identification Numbers.
 *
 * Written by Martino Mancino
 *
 * With thanks to https://github.com/bradg/vin-js/blob/master/vin-js.js
 *
 * Description:
 * Valid VINs are exactly 17 characters long, its composed of capital letters (except "I","O" and "Q") and digits.
 * The 9th character is a MODULUS 11 check digits.
 * Rules at https://en.wikipedia.org/wiki/Vehicle_identification_number
 *
 * Usage:
 * vinValidator('12345678901234567');
 *
 * returns:
 * isValid: either true or false
 * hasInvalidCharacters: either true or false
 *
 */

interface letterMapProp {
  [key: string]: number;
}

export const vinValidator = (vin: string) => {
  const uppercaseVin = vin.toUpperCase();
  const VIN_REGEX = /^[(A-H|J-N|P|R-Z|0-9)]{17}$\b/i;
  const vinInvalidLetters = ['O', 'I', 'Q'];
  const hasInvalidCharacters = vinInvalidLetters.some((char) => uppercaseVin.includes(char));
  const isVinEntryValid = VIN_REGEX.test(vin) && checksumValidation(uppercaseVin);

  return {
    isValid: isVinEntryValid && !hasInvalidCharacters,
    hasInvalidCharacters,
  };
};

const checksumValidation = (vin: string) => {
  const vinArray = vin.split('');
  const checksum = vinArray[8];

  const letterMap: letterMapProp = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    P: 7,
    R: 9,
    S: 2,
    T: 3,
    U: 4,
    V: 5,
    W: 6,
    X: 7,
    Y: 8,
    Z: 9,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    0: 0,
  };
  const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];

  let total = vinArray.reduce((acc, element, index) => {
    const value = letterMap[element] * weights[index];
    return acc + value;
  }, 0);

  const module = total % 11;
  // VIN validity conditions
  // Module = 9th character of the VIN
  // If the Module is 10, the 9th character of the VIN must be X
  const isVinValid = module === parseInt(checksum) || (module === 10 && checksum === 'X');

  return isVinValid;
};

import styled from 'styled-components';

import { Text } from 'src/components/primitives/Text';
import { Translation } from '../../Translation';

type MMYScreen = {
  showVinScreen: () => void;
};

export const MMYScreen: React.FC<MMYScreen> = ({ showVinScreen, children }) => {
  return (
    <>
      <Text as="p" size="lg">
        <Translation id="journey.dataCollector.mmy.description" />
      </Text>
      <Container>{children}</Container>
      <Container>
        <Text
          as="p"
          size="lg"
          onClick={showVinScreen}
          style={{ textAlign: 'center' }}
          weight="medium"
          color="tertiary"
        >
          <Translation id="journey.dataCollector.mmy.navigationLink" />
        </Text>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--s-4);
`;

export const MMYContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--s-4);
`;

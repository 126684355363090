import { useEffect, useReducer } from 'react';
import { SingleValue } from 'react-select';

import { Vin as VinType } from 'src/Api';
import { vinValidator } from './VinValidator';

type MMYOption = {
  value: string;
  label: string;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setVin':
      return { ...state, vin: action.value };
    case 'setMake':
      return { ...state, make: action.value, modelOptions: [], model: '', yearOptions: [], year: '' };
    case 'setModel':
      return { ...state, model: action.value, yearOptions: [], year: '' };
    case 'setYear':
      return { ...state, year: action.value };
    case 'setMakeOptions':
      return { ...state, makeOptions: action.value };
    case 'setModelOptions':
      return { ...state, modelOptions: action.value };
    case 'setYearOptions':
      return { ...state, yearOptions: action.value };
    case 'setShowVinScreen':
      return { ...state, vinEntryMode: action.value };
    default:
      throw new Error();
  }
};

export const useVin = (value: VinType, allowMMYEntry: boolean) => {
  const [{ vin, make, model, year, vinEntryMode, makeOptions, modelOptions, yearOptions }, dispatch] =
    useReducer(reducer, value);

  useEffect(() => {
    if (!allowMMYEntry) return;

    fetch('/api/getCarMakes', {
      method: 'GET',
    }).then(async (res) => {
      const body = await res.json();
      dispatch({ type: 'setMakeOptions', value: body.data });
    });
  }, [allowMMYEntry]);

  useEffect(() => {
    if (make !== '') {
      fetch(`/api/getCarModels?make=${make}`, {
        method: 'GET',
      }).then(async (res) => {
        const body = await res.json();
        dispatch({ type: 'setModelOptions', value: body.data });
      });
    }
  }, [make]);

  useEffect(() => {
    if (make !== '' && model !== '') {
      fetch(`/api/getCarYears?model=${model}`, {
        method: 'GET',
      }).then(async (res) => {
        const body = await res.json();
        dispatch({ type: 'setYearOptions', value: body.data });
      });
    }
  }, [make, model]);

  const onVinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'setVin', value: e.target.value });
  };

  const onMakeChange = (selectedOption: SingleValue<MMYOption>) => {
    if (selectedOption) {
      dispatch({ type: 'setMake', value: selectedOption.value });
    }
  };

  const onModelChange = (selectedOption: SingleValue<MMYOption>) => {
    if (selectedOption) {
      dispatch({ type: 'setModel', value: selectedOption.value });
    }
  };

  const onYearChange = (selectedOption: SingleValue<MMYOption>) => {
    if (selectedOption) {
      dispatch({ type: 'setYear', value: selectedOption.value });
    }
  };

  const toggleVinInputScreen = () => {
    dispatch({ type: 'setShowVinScreen', value: !vinEntryMode });
  };

  // This is needed to avoid to show the error on an empty input
  const isVinEmpty = !vin;
  // Validate VIN
  const vinHasInvalidLetters = vinValidator(vin).hasInvalidCharacters;
  const isVinEntryValid = vinEntryMode && vinValidator(vin).isValid;
  const isMMYEntryValid = !vinEntryMode && model.length > 0 && make.length > 0 && !!year;
  const isValid = isVinEntryValid || isMMYEntryValid;

  return {
    isValid,
    isVinEmpty,
    vinHasInvalidLetters,
    vinEntryMode,
    toggleVinInputScreen,
    onContinueData: {
      make,
      model,
      year,
      vin,
      vinEntryMode,
    },
    vinInputProps: {
      value: vin,
      onChange: onVinChange,
    },
    makeInputProps: {
      value: { value: make, label: make },
      options: makeOptions,
      onChange: onMakeChange,
    },
    modelInputProps: {
      value: { value: model, label: model },
      options: modelOptions,
      onChange: onModelChange,
    },
    yearInputProps: {
      value: { value: year, label: year },
      options: yearOptions,
      onChange: onYearChange,
    },
  };
};

import * as t from 'io-ts';
import { IO, UIO } from './IO';
import { Maybe } from './Maybe';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Either';
import localForage from 'localforage';
export interface Store<K extends string, V> {
  get: (key: K) => UIO<Maybe<V>>;
  set: (key: K, value: V) => UIO<Store<K, V>>;
  delete: (key: K) => UIO<Store<K, V>>;
}

export class LocalStorageStore<K extends string, V> implements Store<K, V> {
  constructor(private readonly codec: t.Type<V>) {}

  public get = (key: K): UIO<Maybe<V>> =>
    IO.fromPromise(() =>
      localForage
        .getItem(`tractable-${key}`)
        .catch(() => null)
        .then((value) =>
          Maybe.fromNullable(value).flatMap((json) =>
            pipe(
              this.codec.decode(json),
              fold(() => Maybe.nothing, Maybe.just)
            )
          )
        )
    );

  public set = (key: K, value: V, showErrorModalCallback?: () => void): UIO<Store<K, V>> =>
    IO.sync<void, never>(() =>
      Maybe.tryCatch(() => localForage.setItem(`tractable-${key}`, value), showErrorModalCallback)
    ).map<Store<K, V>>(() => this);

  public delete = (key: K): UIO<Store<K, V>> =>
    IO.sync<void, never>(() => localForage.removeItem(`tractable-${key}`)).map<Store<K, V>>(() => this);

  public truncate = (ignoreKey: K): UIO<Store<K, V>> =>
    IO.sync<void, never>(() => {
      const mainStorageKey = `tractable-${ignoreKey}`;
      const offlineTrakingStoreKey = 'tractable-offlineTrackingEventsStack';
      const keysToIgnore: string[] = [mainStorageKey, offlineTrakingStoreKey];

      return localForage
        .keys()
        .then((keys) =>
          Promise.all(keys.map((key) => !keysToIgnore.includes(key) && localForage.removeItem(key)))
        );
    }).map<Store<K, V>>(() => this);
}

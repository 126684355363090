import styled from 'styled-components';
import { DropzoneOptions } from 'react-dropzone';
import { FileUploader } from 'src/components/FileUploader';
import { ButtonPrimary, ButtonSecondary } from 'src/components/primitives/Button';
import { Camera } from 'src/components/primitives/Icons';
import { Translation } from './Translation';
import { ButtonProps, ExternalLink } from './primitives/Action';
import { PhoneCameraOnly } from 'src/core/ClientConfig/DataCollector';

type TakePhotoButton = {
  uploadOptions: DropzoneOptions;
  phoneCameraOnly: PhoneCameraOnly;
  isPlural?: boolean;
};

export const TakePhotoButton = ({
  uploadOptions,
  phoneCameraOnly,
  isPlural = false,
}: TakePhotoButton): JSX.Element => (
  <FileUploader options={uploadOptions} phoneCameraOnly={phoneCameraOnly}>
    <ButtonPrimary tag="Button" type="button" data-testid="take-photos">
      <StyledCamera />{' '}
      <Translation
        id={isPlural ? `journey.dataCollector.button.takePhotos` : `journey.dataCollector.button.takePhoto`}
      />
    </ButtonPrimary>
  </FileUploader>
);

export const TakePhotoSecondaryButton = ({
  uploadOptions,
  phoneCameraOnly,
  isPlural,
}: TakePhotoButton): JSX.Element => (
  <FileUploader options={uploadOptions} phoneCameraOnly={phoneCameraOnly}>
    <ButtonSecondary tag="Button" type="button" data-testid="take-photos">
      <StyledCamera />{' '}
      <Translation
        id={isPlural ? `journey.dataCollector.button.takePhotos` : `journey.dataCollector.button.takePhoto`}
      />
    </ButtonSecondary>
  </FileUploader>
);

type ContinueButton = ButtonProps & {
  loading?: boolean;
};

export const ContinueButton: React.FC<ContinueButton> = (props) => (
  <ButtonPrimary tag="Button" data-testid="continue" {...props}>
    <Translation id="journey.dataCollector.button.continue" />
  </ButtonPrimary>
);

type GoBackButton = {
  onClick: () => void;
};

export const GoBackButton = ({ onClick }: GoBackButton): JSX.Element => (
  <UppercaseButton tag="Button" type="button" data-testid="go-back" onClick={onClick}>
    <Translation id="cta.goBack" />
  </UppercaseButton>
);

export const OpenInChromeButton = ({ href }: ExternalLink): JSX.Element => (
  <UppercaseButton tag="ExternalLink" type="button" data-testid="go-back" href={href}>
    <Translation id="cta.openInChrome" />
  </UppercaseButton>
);

export const Buttons = styled.div`
  display: grid;
  grid-gap: var(--s-6);
  width: 100%;
`;

const UppercaseButton = styled(ButtonPrimary)`
  text-decoration: uppercase;
  margin-top: auto;
`;

const StyledCamera = styled(Camera)`
  margin-right: var(--s-1);
`;

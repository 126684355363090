import React from 'react';
import { TranslationStrings } from 'src/core/Translation';

export const useTranslations = () => {
  const context = React.useContext(TranslationContext);
  return context;
};

export const fallbackTanslations: TranslationStrings = {
  'journey.complete.inScope.additionalCostsTotal': 'Additional cost',
  'journey.complete.inScope.additionalHoursTotal': 'Additional labour hours',
  'journey.complete.inScope.body':
    'We have generated this estimate based on your vehicle make and model, photos, and accident circumstances.',
  'journey.complete.inScope.disclaimer':
    'Disclaimer: These costs are not final. We may adjust this amount upon review by a claim handler.',
  'journey.complete.inScope.labourTotal': 'Labour price',
  'journey.complete.inScope.nextSteps.title': 'Next steps',
  'journey.complete.inScope.nextSteps.body':
    'We’ll give you a call within 2 business days to help you decide if you want to make a claim or not.',
  'journey.complete.inScope.operation.PAINT': 'Paint',
  'journey.complete.inScope.operation.REPAIR': 'Repair',
  'journey.complete.inScope.operation.REPLACE': 'Replace',
  'journey.complete.inScope.operation.BLEND': 'Blend',
  'journey.complete.inScope.operation.REPLACE_AUXILIARY': 'Replace auxiliary',
  'journey.complete.inScope.operation.REPAIR_PAINT': 'Repair paint',
  'journey.complete.inScope.paintTotal': 'Paint price',
  'journey.complete.inScope.partsTotal': 'Parts price',
  'journey.complete.inScope.repairCostInclTax': 'Total repair cost (incl. tax)',
  'journey.complete.inScope.title': 'Initial Repair Estimate',
  'journey.complete.outOfScope.title': 'You’re all done!',
  'journey.complete.outOfScope.body':
    'We’ll review your photos and email you an initial repair estimate in the next few days.',
  'journey.complete.name': 'You’re all done!',
  'journey.complete.message': 'We will get in touch with you soon',
  'journey.complete.rating.name': 'Overall, using this app was:',
  'journey.complete.rating.veryEasy': 'Very Easy',
  'journey.complete.rating.veryHard': 'Very Hard',
  'journey.complete.inScope.message':
    'We’ll give you a call within 2 business days to help you decide if you want to make a claim or not.',
  'journey.complete.sompo.message':
    'We’ll give you a call within 2 business days to help you decide if you want to make a claim or not.',
  'journey.complete.thankYou.heading': 'Thank you for your feedback!',
  'journey.complete.thankYou.description': 'You may close the app.',
  'journey.complete.takeScreenshot': 'Take a screenshot to save the estimate for future reference',
  'journey.complete.roughRepairCost': 'Rough repair cost',
  'journey.complete.cta.title': 'Next steps',
  'journey.complete.cta.body':
    'Give us a call at {{clientPhoneNumber}} to schedule a collision repair appointment at your nearest center.',
  'journey.complete.cta.buttonText': '{{displayName}} call center',
  'journey.dataCollector.additionalPhotos.name': 'Additional Photos',
  'journey.dataCollector.additionalPhotos.takePhotosOfTheFollowing': 'Take photos of the following:',
  'journey.dataCollector.additionalPhotos.viewExamplePhotos': 'View example photos',
  'journey.dataCollector.additionalPhotos.tip.vinLookup':
    '**Two common places for VIN:** (1) driver’s side dashboard and (2) driver’s side door jamb.',
  'journey.dataCollector.additionalPhotos.tip.vinExample': '**Example VIN:** WF05XXGCC5FC05702',
  'journey.dataCollector.button.continue': 'Continue',
  'journey.dataCollector.button.takePhotos': 'Take photos',
  'journey.dataCollector.button.takePhoto': 'Take photo',
  'journey.dataCollector.button.takeVideo': 'Take video',
  'journey.dataCollector.button.retakeVideo': 'Retake video',
  'journey.dataCollector.button.edit': 'Edit',
  'journey.dataCollector.button.upload': 'Upload',
  'journey.dataCollector.button.uploadAndContinue': 'Upload and Continue',
  'journey.dataCollector.button.uploading': 'Uploading...',
  'journey.dataCollector.estimate.loading.first': 'Identifying vehicle...',
  'journey.dataCollector.estimate.loading.second': 'Analyzing photos...',
  'journey.dataCollector.estimate.loading.third': 'Generating estimate...',
  'journey.dataCollector.examplePhoto.mileage': 'Mileage',
  'journey.dataCollector.examplePhoto.preExisitingDamage': 'Pre-existing damage',
  'journey.dataCollector.examplePhoto.registrationCertificate': 'Registration certificate',
  'journey.dataCollector.examplePhoto.friendlyDeclaration': 'Friendly declaration',
  'journey.dataCollector.examplePhoto.vinPlate': 'VIN plate',
  'journey.dataCollector.imageUpload.cancel': 'Cancel',
  'journey.dataCollector.imageUpload.failed': 'Upload failed',
  'journey.dataCollector.imageUpload.retry': 'Tap to retry',
  'journey.dataCollector.photoReview.loading.duration': 'This can take up to a minute',
  'journey.dataCollector.photoReview.loading.sending': 'Sending to {{displayName}}...',
  'journey.dataCollector.photoReview.name': 'Photo Review',
  'journey.dataCollector.photoReview.review.missingPhotos': 'We are missing photos for the following parts:',
  'journey.dataCollector.photoReview.review.tip':
    'Take a range of photos, some close up and some further away.',
  'journey.dataCollector.photoReview.timer.doNotCloseTab': 'Don’t close this tab whilst we’re scanning.',
  'journey.dataCollector.photoReview.timer.duration': 'This can take up to 1 minute.',
  'journey.dataCollector.photoReview.timer.processingYourRequest': 'We are now processing your request.',
  'journey.dataCollector.vehiclePhotos.description':
    'Take a photo from each corner of the vehicle – in any order.',
  'journey.dataCollector.vehiclePhotos.name': 'Vehicle Photos',
  'journey.dataCollector.vehiclePhotos.takePhotosLikeThis': 'Take photos like this',
  'journey.dataCollector.vehiclePhotos.useFlashTip': 'Dark outside? Ensure your flash is on',
  'journey.dataCollector.vehiclePhotos.useSavedPhotosTip':
    'Already taken photos? Upload photos from your gallery',
  'journey.dataCollector.vehiclePhotos.tip.visiblePlate':
    'Ensure the car registration plate is visible in at least one photo',
  'journey.dataCollector.vehiclePhotos.error.duplicatePhotos':
    '**Duplicate photos removed:** Upload 4 unique vehicle photos. You cannot re-use photos from the Damage Photos step.',
  'journey.dataCollector.vehiclePhotos.tip.duplicatePhotos':
    '**No duplicates:** Do not use the same photos you uploaded for Damage Photos',
  'journey.dataCollector.vehicleSidePhotos.description':
    'Take 4 photos of the vehicle front, back, and right and left sides.',
  'journey.dataCollector.vehicleSidePhotos.name': 'Vehicle Side Photos',
  'journey.dataCollector.vin.name': 'VIN',
  'journey.dataCollector.vin.description': 'What is your vehicle identification number (VIN)?',
  'journey.dataCollector.vin.navigationLink': 'Unable to find VIN?',
  'journey.dataCollector.vin.input.label': 'Vehicle identification number',
  'journey.dataCollector.vin.input.error.invalid': 'Enter a valid 17-character VIN',
  'journey.dataCollector.vin.input.error.invalidCharacters':
    'A VIN cannot contain the following letters: O, I, Q',
  'journey.dataCollector.vin.select.make.label': 'Make',
  'journey.dataCollector.vin.select.model.label': 'Model',
  'journey.dataCollector.vin.select.year.label': 'Year',
  'journey.dataCollector.vin.commonLocations.one': 'Driver’s side dashboard',
  'journey.dataCollector.vin.commonLocations.two': 'Driver’s side door jamb',
  'journey.dataCollector.mmy.description': 'In place of the VIN, enter your vehicle make, model, and year.',
  'journey.dataCollector.mmy.navigationLink': 'Unable to find Make/Model/Year?',
  'journey.dataCollector.numberOfImages': '{{numberOfImages}} photos uploaded',
  'journey.dataCollector.damageLocation.name': 'Damage Location',
  'journey.dataCollector.damageLocation.description': 'Tap where the damage is located',
  'journey.dataCollector.damagePhotos.name': 'Damage Photos',
  'journey.dataCollector.damagePhotos.description': 'Take photos of the damage, E.g:',
  'journey.dataCollector.damagePhotos.imageLimitDescription':
    'Take at least three photos, but you can take as many as you like.',
  'journey.dataCollector.damagePhotos.error.duplicatePhotos':
    '**Duplicate photo(s) removed:** Upload at least {{min}} unique damage photos. You cannot re-use photos.',
  'journey.dataCollector.damagePhotos.tip.duplicatePhotos':
    '**No duplicates:** Upload at least 3 unique damage photos',
  'journey.dataCollector.additionalDetails.name': 'Additional Details',
  'journey.dataCollector.additionalDetails.question.airbagsDeployed': 'Have any airbags deployed?',
  'journey.dataCollector.additionalDetails.question.isDrivable': 'Is the vehicle drivable?',
  'journey.dataCollector.additionalDetails.question.mileage': 'What is the vehicle’s mileage?',
  'journey.dataCollector.additionalDetails.question.windshieldShattered': 'Is the windscreen shattered?',
  'journey.dataCollector.additionalDetails.question.agreeUsingGreenParts':
    'Do you agree with your bodyshop using green parts in case some parts need to be replaced?',
  'journey.dataCollector.additionalDetails.answer.yes': 'Yes',
  'journey.dataCollector.additionalDetails.answer.no': 'No',
  'journey.dataCollector.vehicleMileage.name': 'Vehicle Mileage',
  'journey.dataCollector.vehicleMileage.description':
    'This’ll help us better understand the condition of your vehicle.',
  'journey.dataCollector.damageReview.name': 'Damage Review',
  'journey.dataCollector.damageReview.review.damagedParts': 'Tap all parts that are damaged',
  'journey.dataCollector.damageReview.damaged': 'Damaged',
  'journey.dataCollector.damageReview.partsSelected': '{{numberOfPartsSelected}} damaged parts selected',
  'journey.dataCollector.damageReview.partSelected': '{{numberOfPartsSelected}} damaged part selected',
  'journey.dataCollector.videoInspection.name': 'Record Video',
  'journey.dataCollector.videoInspection.description': 'Record Video',
  'journey.dataCollector.videoInspection.bullet.one': 'Record Video',
  'journey.dataCollector.videoInspection.bullet.two': 'Record Video',
  'journey.dataCollector.videoInspection.bullet.three': 'Record Video',
  'journey.dataCollector.videoInspection.cameraPermissionError':
    'To take video and photos of a vehicle, allow us to use your camera.',
  'journey.dataCollector.videoInspection.videoTooShortError':
    'The video must be longer than 10 seconds. Try retaking the video.',
  'journey.dataCollector.videoInspection.uploadError': 'Unable to upload video. Try uploading it again. ',
  'journey.dataCollector.videoInspection.confirmation.bullet.one':
    'Can you always see the top and bottom of the vehicle?',
  'journey.dataCollector.videoInspection.confirmation.bullet.two': 'Is the video steady and clear?',
  'journey.dataCollector.smartVideoInspection.instructions.heading': 'Take a short video of your vehicle.',
  'journey.dataCollector.smartVideoInspection.instructions.bullet.one':
    'Keep the **top** and **bottom** of the vehicle visible in the video at all times',
  'journey.dataCollector.smartVideoInspection.instructions.bullet.two':
    'Walk in a full circle around the vehicle at a steady, relaxed pace',
  'journey.dataCollector.button.recordVideo': 'Record Video',
  'journey.dataCollector.vinProof.name': 'VIN',
  'journey.dataCollector.vinProof.description':
    'A vehicle identification number is a 17-digit serial number printed on every vehicle.',
  'journey.dataCollector.vinProof.commonLocationsTitle': 'The most common locations are:',
  'journey.dataCollector.vinProof.commonLocations.one': 'Driver’s side dashboard',
  'journey.dataCollector.vinProof.commonLocations.two': 'Driver’s side door pillar',
  'journey.dataCollector.vinProof.commonLocations.modalLink': 'Where can I find the VIN?',
  'damageLocation.Front Center': 'Front Center',
  'damageLocation.Left Front Corner': 'Left Front Corner',
  'damageLocation.Right Front Corner': 'Right Front Corner',
  'damageLocation.Rear Center': 'Rear Center',
  'damageLocation.Left Rear Corner': 'Left Rear Corner',
  'damageLocation.Right Rear Corner': 'Right Rear Corner',
  'damageLocation.Left Side': 'Left Side',
  'damageLocation.Right Side': 'Right Side',
  'damageLocation.Left Rear Side': 'Left Rear Side',
  'damageLocation.Right Rear Side': 'Right Rear Side',
  'damageLocation.Left Front Side': 'Left Front Side',
  'damageLocation.Right Front Side': 'Right Front Side',
  'label.ifAny': 'if any',
  'modal.button.ok': 'OK',
  'modal.examplePhotos.title': 'Example photos',
  'modal.existingDamage.cracks': 'Cracks on plastic parts and glass',
  'modal.existingDamage.dents': 'Noticeable dents or indentations',
  'modal.existingDamage.includes': 'Pre-existing damage may include: ',
  'modal.existingDamage.scratches': 'Scratches or abrasions',
  'modal.existingDamage.title': 'What is pre-existing damage?',
  'onboarding.button.start': 'Start',
  'onboarding.slideOne.infoOne': "We need some photos to help us identify your car's",
  'onboarding.slideOne.infoOne.modalLink': 'pre-existing damage',
  'onboarding.slideOne.infoTwo': 'This will usually only take a few minutes.',
  'onboarding.slideTwo.infoOne': 'Try to get the whole vehicle in view, in good lighting.',
  'onboarding.slideTwo.infoTwo': 'Please get your registration certificate and car keys ready. ',
  'onboarding.slideTwo.tip.key': 'You’ll need to bring your vehicle keys for the mileage',
  'onboarding.damageClaim.slideOne.infoOne': 'We need some photos to help process your claim',
  'onboarding.damageClaim.slideTwo.infoOne': 'We’ll need photos of the entire vehicle, not just the damage',
  'onboarding.damageClaim.slideTwo.infoTwo': 'Try to get the whole vehicle in view, in good lighting',
  'onboarding.videoInspection.slideOne.infoOne':
    'We need a short video to help us assess the condition of the vehicle.',
  'onboarding.videoInspection.slideTwo.infoOne':
    'Park the vehicle in a safe place where you do a 360° walk around.',
  'onboarding.videoInspection.slideTwo.infoTwo': 'Try to get the whole vehicle in view, in good lighting.',
  'splash.button.acceptTerms': 'Accept',
  'splash.termsOfUse.heading': 'Terms of Use',
  'splash.text.termsOfUse': 'By using this app you agree to our [terms of use]({{termsOfUseHref}}).',
  'splash.text': '',
  'partName.BONNET': 'Bonnet',
  'partName.FLWING': 'Front Left Wing',
  'partName.FRWING': 'Front Right Wing',
  'partName.FBUMPER': 'Front Bumper',
  'partName.BBUMPER': 'Rear Bumper',
  'partName.FLDOOR': 'Front Left Door',
  'partName.FRDOOR': 'Front Right Door',
  'partName.BLWING': 'Rear Left Wing',
  'partName.BRWING': 'Rear Right Wing',
  'partName.BOOTLID': 'Bootlid',
  'partName.RRDOOR': 'Rear Right Door',
  'partName.RLDOOR': 'Rear Left Door',
  'partName.LHEADLAMP': 'Left Headlamp',
  'partName.RHEADLAMP': 'Right Headlamp',
  'partName.LTAILLAMP': 'Left Taillamp',
  'partName.RTAILLAMP': 'Right Taillamp',
  'partName.GRILLE': 'Grille',
  'partName.ROOF': 'Roof',
  'partName.LMIRROR': 'Left Mirror',
  'partName.RMIRROR': 'Right Mirror',
  'partName.LROCKERPANEL': 'Left Sill',
  'partName.RROCKERPANEL': 'Right Sill',
  'partName.FWINDSHIELD': 'Front Windscreen',
  'partName.RWINDSHIELD': 'Rear Windscreen',
  'partName.LAPILLAR': 'Left A-Pillar',
  'partName.RAPILLAR': 'Right A-Pillar',
  'cta.goBack': 'Go Back',
  'cta.openInChrome': 'Open in Chrome',
  'repairerDemo.acceptSpeedyPayment': 'Accept speedy payment',
  'repairerDemo.appointmentConfirmation.body.first': 'You have booked an appointment at {{bodyshopName}} for',
  'repairerDemo.appointmentConfirmation.body.date': '{{date}} at {{time}}',
  'repairerDemo.appointmentConfirmation.body.second': 'Your rental car is ready to be picked up at:',
  'repairerDemo.appointmentConfirmation.cta': 'Add to calendar',
  'repairerDemo.appointmentConfirmation.title': 'Appointment booked',
  'repairerDemo.appointmentSchedule.cta': 'Book appointment',
  'repairerDemo.appointmentSchedule.title': 'Appointment Schedule',
  'repairerDemo.bodyshopDetails.callNumber': 'Call number',
  'repairerDemo.bodyshopDetails.address': 'Address',
  'repairerDemo.bodyshopDetails.cta': 'Schedule appointment',
  'repairerDemo.bodyshopDetails.photos': 'Photos',
  'repairerDemo.bodyshopDetails.reviews': 'Reviews',
  'repairerDemo.bodyshopDetails.services': 'Services',
  'repairerDemo.bodyshopDetails.visitWebsite': 'Visit website',
  'repairerDemo.cashSettlement.body': 'Put the money in any {{country}} bank account in the next 5 minutes.',
  'repairerDemo.cashSettlement.title': 'Speedy payment offer accepted!',
  'repairerDemo.cashSettlement.enterBankDetails': 'Enter bank details',
  'repairerDemo.cashSettlement.error.body': 'There was an issue generating your speedy payment',
  'repairerDemo.cashSettlement.error.title': 'Please try again',
  'repairerDemo.estimate.body':
    'We have generated this estimate based on your vehicle make and model, photos, and accident circumstances.',
  'repairerDemo.estimate.heading': 'Your car is repairable!',
  'repairerDemo.locationLoading': 'Getting your location',
  'repairerDemo.scheduleCarRepair': 'Schedule car repair',
  'repairerDemo.searchPlaceholder': 'Enter your address',
  'modal.browserError.memory.title': 'Something went wrong',
  'modal.browserError.memory.description':
    'Sorry, we were not able to save your photos as your browser ran out of memory. Please clear your browser data and refresh the page or use another browser and try again.',
  'modal.browserError.memory.cta': 'Got it',
  'modal.uploadPhotos.progressBar.uploading': 'Uploading...',
  'modal.uploadPhotos.progressBar.uploadCompleted': 'Upload completed',
  'modal.uploadPhotos.progressBar.uploadError': 'Upload error',
  'modal.uploadPhotos.progressBar.uploadPaused': 'Upload paused',
  'modal.uploadPhotos.progressBar.photos': 'photos',
  'modal.uploadPhotos.offline.title': 'Offline',
  'modal.uploadPhotos.offline.description.first':
    'Your network is unavailable. No worries! We’ll wait until you’re ready.',
  'modal.uploadPhotos.offline.description.second':
    'Make sure you have a strong data or WiFi connection before uploading your photos.',
  'modal.uploadPhotos.offline.cta': 'Upload Photos',
  'modal.uploadPhotos.offline.card.description':
    'Your network is unavailable. Check your data or WiFi connection.',
  'modal.uploadPhotos.offline.card.cta': 'Retry uploading',
  'modal.uploadPhotos.error.card.title': 'Upload Error',
  'modal.uploadPhotos.error.card.description': 'Something went wrong while uploading your images.',
  'modal.uploadPhotos.online.title': 'Upload Photos',
  'modal.uploadPhotos.online.cta': 'Continue',
  'modal.systemError.title': 'System Error',
  'modal.systemError.description.first':
    'Something went wrong. Please wait a couple of seconds before trying again.',
  'modal.systemError.description.second':
    'Please contact {{clientDisplayName}} if it does not work after a few tries.',
  'modal.systemError.cta': 'Got it',
  'notification.recording.tooclose': 'Too close! Take a step back.',
  'notification.recording.toofast': 'Slow down',
  'notification.recording.toofar': 'Move closer to the vehicle.',
  'notification.recording.notvisible': 'Make sure the vehicle is clearly visible.',
  'errorPage.title': 'Something went wrong',
  'errorPage.description':
    'Sorry, we’re working to fix the problem. Wait a couple seconds before trying again. If you need any help, contact us.',
  'browserNotSupportedPage.title': 'Use a different browser',
  'browserNotSupportedPage.description':
    'Sorry, we do not support this browser. Open this link on Chrome or Firefox. If you need any help, contact us.',
  'expiredPage.title': 'You need a new link',
  'expiredPage.description': 'Sorry, this link has expired. Contact us to get a new link.',
  'sessionNotFoundPage.title': 'Something went wrong',
  'sessionNotFoundPage.description':
    'Sorry, we’re working to fix the problem. If you need any help, contact us.',
  'wrongDevicePage.title': 'Use a different device',
  'wrongDevicePage.description':
    'Sorry, this link only works on the first device used to open this link. Go back to that device to open the link. If you need any help, contact us.',
  'global.poweredByTractable': 'Powered by Tractable',
};

export const TranslationContext = React.createContext<TranslationStrings>(fallbackTanslations);

import * as t from 'io-ts';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IO } from 'src/lib/IO';

/**
 * Represents an error thrown while decoding data.
 */
export class DecodeError extends Error {
  tag: 'DecodeError' = 'DecodeError';
}

/**
 * Parses errors thrown when decoding goes wrong.
 *
 * These are presented with the likely most helpful error first.
 */
const parseErrors = (decodeErrors: Array<t.ValidationError>): string =>
  decodeErrors
    .flatMap((decodeError) =>
      decodeError.context
        .filter(({ key }) => !!key)
        .map(
          ({ key, type, actual }) =>
            `Field ${key} should have type ${type.name} but has value ${JSON.stringify(actual)}`
        )
        .reverse()
    )
    .join('\n');

/**
 * Decodes the data into an `io-ts` runtime representation of that value. Wraps the decoded value in
 * an IO<A, DecodeError>.
 *
 * @template A The decoded type of the data.
 *
 * @param decode The function that will be used to decode the value.
 * @returns The decoded data lifted into an IO<A, DecodeError>
 */
export const DecodeData =
  <A>(decode: (u: unknown) => t.Validation<A>) =>
  (data: unknown): IO<A, DecodeError> =>
    pipe(
      decode(data),
      fold<t.Errors, A, IO<A, DecodeError>>(
        // On failed decode lift the errors into an IO<A, DecodeError>
        (decodeErrors) => {
          const errorMessage = parseErrors(decodeErrors);

          return IO.fail(new DecodeError(`There was a decode error\n${errorMessage}`));
        },
        // On successful decode lift decoded value (A) into an IO<A, DecodeError>
        (a) => IO.pure(a)
      )
    );

import { match } from './pattern';

const identity = <A>(a: A): A => a;

export class Maybe<A> {
  static just = <A>(a: A): Maybe<A> => new Maybe({ tag: 'Just', value: a });

  static nothing = new Maybe<never>({ tag: 'Nothing' });

  static fromNullable = <A>(a: A | null | undefined): Maybe<A> =>
    a === null || a === undefined ? Maybe.nothing : Maybe.just(a);

  static tryCatch = <A>(f: () => A, catchSideEffect?: () => void): Maybe<A> => {
    try {
      return Maybe.just(f());
    } catch (e) {
      if (catchSideEffect) {
        catchSideEffect();
      }
      return Maybe.nothing;
    }
  };

  static fromRaw = <A>(raw: _Maybe<A>): Maybe<A> => new Maybe(raw);

  private constructor(private readonly value: _Maybe<A>) {}

  map = <B>(f: (a: A) => B): Maybe<B> => this.flatMap((a) => Maybe.just(f(a)));

  flatMap = <B>(f: (a: A) => Maybe<B>): Maybe<B> => this.fold(Maybe.nothing, f);

  /**
   * If this maybe is empty then returns `onNothing`. If this maybe has
   * a value then returns the result of calling `onJust`.
   */
  fold = <B>(onNothing: B, onJust: (a: A) => B): B =>
    match(this.value, {
      Just: ({ value }) => onJust(value),
      Nothing: () => onNothing,
    });

  /**
   * If this maybe is empty then returns the result of calling `onNothing`. If this maybe has
   * a value then returns the result of calling `onJust`.
   */
  foldL = <B>(onNothing: () => B, onJust: (a: A) => B): B =>
    match(this.value, {
      Just: ({ value }) => onJust(value),
      Nothing: onNothing,
    });

  getOrElse(a: A): A {
    return this.fold(a, identity);
  }

  filter = (p: (a: A) => boolean): Maybe<A> => this.flatMap((a) => (p(a) ? Maybe.just(a) : Maybe.nothing));

  isNothing: boolean = this.fold(true, () => false);

  isJust = !this.isNothing;

  exists = (p: (a: A) => boolean): boolean => this.fold(false, p);

  raw: _Maybe<A> = this.value;
}

export type _Maybe<A> = Nothing | Just<A>;

type Nothing = { tag: 'Nothing' };
type Just<A> = { tag: 'Just'; value: A };

import React from 'react';
import styled from 'styled-components';
import { UnprocessedImage } from 'src/core/queries';
import { match } from 'src/lib/pattern';
import { Text } from 'src/components/primitives/Text';
import { CircleCheck } from 'src/components/primitives/Icons';
import { Action } from 'src/components/primitives/Action';
import { Translation } from './Translation';
import { Max, Min, PhoneCameraOnly, PhotoDuplicatesCheck } from 'src/core/ClientConfig/DataCollector';
import { ClientId } from 'src/core/ClientConfig/ClientId';
import { Photos } from 'src/hooks/types';
import { Category } from 'src/Api';
import { SessionId } from 'src/Routes';

type Active = {
  id: string;
  header: React.ReactNode;
  button?: React.ReactNode;
  fullHeight?: boolean;
};

type HTMLCustomProps = {
  fullHeight?: boolean;
};

export const Active: React.FC<Active> = ({ id, children, header, button, fullHeight }): JSX.Element => {
  return (
    <ContainerActive data-testid={`${id}-active`} fullHeight={fullHeight}>
      <Heading as="h2" size="xl" weight="medium">
        {header}
      </Heading>
      <ChildContainer>
        <Grid>{children}</Grid>
        {button && <ButtonContainer>{button}</ButtonContainer>}
      </ChildContainer>
    </ContainerActive>
  );
};

type Inactive = {
  id: string;
  header: React.ReactNode;
};

export const Inactive = ({ id, header }: Inactive): JSX.Element => (
  <Container data-testid={`${id}-inactive`}>
    <Opactiy>
      <Heading as="h2" size="xl" weight="medium">
        {header}
      </Heading>
    </Opactiy>
  </Container>
);

type Edit = {
  id: string;
  header: React.ReactNode;
  onEdit: () => void;
};

export const Edit: React.FC<Edit> = ({ id, children, header, onEdit }) => (
  <Container data-testid={`${id}-edit`}>
    <Flex>
      <div>
        <Heading as="h2" size="xl" weight="medium">
          {header}
        </Heading>
        {children && (
          <ChildContainer>
            <Text as="p" size="lg">
              {children}
            </Text>
          </ChildContainer>
        )}
      </div>
      <div>
        <Right>
          <EditButton tag="Button" type="button" onClick={onEdit}>
            <EditButtonText as="span" size="lg" weight="medium">
              <Translation id="journey.dataCollector.button.edit" />
            </EditButtonText>
          </EditButton>
          <CheckIcon />
        </Right>
      </div>
    </Flex>
  </Container>
);

type Complete = {
  id: string;
  header: string;
};

export const Complete: React.FC<Complete> = ({ id, header, children }) => (
  <Container data-testid={`${id}-complete`}>
    <Flex>
      <div>
        <Heading as="h2" size="xl" weight="medium">
          {header}
        </Heading>
        {children && (
          <ChildContainer>
            <Text as="p" size="lg">
              {children}
            </Text>
          </ChildContainer>
        )}
      </div>
      <div>
        <Right>
          <CheckIcon data-testid="check-icon" />
        </Right>
      </div>
    </Flex>
  </Container>
);

export type InactiveDataLoaderProps<T> = {
  id: string;
  tag: 'Edit' | 'Disabled' | 'Saved';
  value: T;
  heading: React.ReactNode;
  children?: React.ReactNode;
  onEdit: () => void;
};

export const InactiveDataCollector = <T,>(props: InactiveDataLoaderProps<T>): JSX.Element =>
  match(props, {
    Edit: ({ heading, onEdit, children }) => (
      <Edit id={props.id} header={heading} onEdit={onEdit}>
        {children}
      </Edit>
    ),
    Disabled: ({ heading }) => <Inactive id={props.id} header={heading} />,
    Saved: ({ heading, children }) => (
      <Complete id={props.id} header={heading}>
        {children}
      </Complete>
    ),
  });

export type PhotoDataCollectorState = {
  submitted: boolean;
  photos: Array<string>;
};
export type PhotosCollectorValue = {
  submitted: boolean;
  photos: Array<UnprocessedImage>;
};
export type ImageUploadProps = Omit<ActiveImageUploadProps & InactiveImageUploadProps, 'heading'> & {
  isActive: boolean;
  number: number;
};

export type ActiveImageUploadProps = {
  id: string;
  heading: React.ReactNode;
  value: PhotoDataCollectorState;
  min: Min;
  max: Max;
  phoneCameraOnly: PhoneCameraOnly;
  photoDuplicatesCheck?: PhotoDuplicatesCheck;
  onContinue: (value: PhotosCollectorValue, setNextActiveStep?: boolean) => void;
  category: Category;
  photos: Photos;
  clientId: ClientId.Type;
  sessionId: SessionId;
};

export type InactiveImageUploadProps = {
  id: string;
  value: PhotoDataCollectorState;
  heading: React.ReactNode;
  setActive: () => void;
  saved?: boolean;
};

export const InactiveImageUploader = ({
  id,
  heading,
  setActive,
  saved,
  value,
}: InactiveImageUploadProps): JSX.Element => (
  <InactiveDataCollector<PhotoDataCollectorState>
    id={id}
    tag={saved ? 'Saved' : value.submitted ? 'Edit' : 'Disabled'}
    heading={heading}
    value={value}
    onEdit={setActive}
  >
    <Translation
      id="journey.dataCollector.numberOfImages"
      numberOfImages={Object.keys(value.photos).length.toString()}
    />
  </InactiveDataCollector>
);

const Grid = styled.div`
  display: grid;
  grid-gap: var(--s-4);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--s-8) 0;
  margin-top: auto;
  & button {
    min-width: 207px;
    width: 100%;
  }
`;

const Heading = styled(Text)`
  color: var(--data-collector-name-color);
`;

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const Opactiy = styled.div`
  opacity: 0.5;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(CircleCheck)`
  color: var(--data-collector-icon-color);
`;

const EditButton = styled(Action)`
  margin-right: var(--s-2);
`;

const EditButtonText = styled(Text)`
  color: var(--data-collector-edit-link-color);
  text-decoration: underline;
`;

const Container = styled.li<HTMLCustomProps>`
  padding: var(--s-4) var(--s-5);
  border-radius: var(--s-1);
  background: var(--color-white);
`;

const ContainerActive = styled(Container)`
  display: flex;
  flex-direction: column;
  ${({ fullHeight }) =>
    fullHeight &&
    `
      min-height: 80vh;
    `}
`;

import styled from 'styled-components';

import { FluidImage } from 'src/core/ClientConfig/Image';
import { Text } from 'src/components/primitives/Text';
import { Translation } from '../../Translation';

import { useCarousel } from 'src/hooks';

type VinScreen = {
  examplePhotos: Array<FluidImage.Type>;
  hideCommonLocations: boolean;
  allowMMYEntry: boolean;
  showMMYScreen: () => void;
};

export const VinScreen: React.FC<VinScreen> = ({
  examplePhotos,
  hideCommonLocations,
  allowMMYEntry,
  showMMYScreen,
  children,
}) => {
  const { carousel, activeIndex } = useCarousel(
    examplePhotos.map(({ alt, src }) => (
      <Slide>
        <img src={src} alt={alt} width={330} height={230} />
      </Slide>
    )),
    true
  );

  return (
    <>
      <Text as="p" size="lg">
        <Translation id="journey.dataCollector.vin.description" />
      </Text>
      {!hideCommonLocations && (
        <List>
          <Item>
            <Text as="p" size="lg">
              <Translation id="journey.dataCollector.vin.commonLocations.one" />
            </Text>
          </Item>
          <Item>
            <Text as="p" size="lg">
              <Translation id="journey.dataCollector.vin.commonLocations.two" />
            </Text>
          </Item>
        </List>
      )}
      {carousel}
      <Dots>
        {examplePhotos.map((_, i) => (activeIndex === i ? <DotActive key={i} /> : <Dot key={i} />))}
      </Dots>
      <Container>{children}</Container>
      {allowMMYEntry && (
        <Container>
          <Text
            as="p"
            size="lg"
            onClick={showMMYScreen}
            style={{ textAlign: 'center' }}
            weight="medium"
            color="tertiary"
          >
            <Translation id="journey.dataCollector.vin.navigationLink" />
          </Text>
        </Container>
      )}
    </>
  );
};

const Slide = styled.div`
  border-radius: var(--s-2);
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--s-4);
`;

const List = styled.ol`
  padding: var(--s-1) 0;
  list-style: disc;
  margin-left: var(--s-4);
`;

const Dots = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.li`
  width: var(--s-2);
  height: var(--s-2);
  border-radius: 50%;
  background: var(--color-white);
  border: 1px solid var(--color-lightGrey);
  margin: 0 var(--s-1);
`;

const DotActive = styled.li`
  width: var(--s-2);
  height: var(--s-2);
  border-radius: 50%;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  margin: 0 var(--s-1);
`;

const Item = styled.li``;

export const noop = (): void => {};

export const identity = <A>(a: A): A => a;

export const andThrow =
  (message: string) =>
  (_: unknown): never => {
    throw new Error(message);
  };

export const constant =
  <A>(a: A) =>
  (): A =>
    a;

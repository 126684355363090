import React from 'react';
import { wrap } from 'popmotion';
import { AnimatePresence, motion } from 'framer-motion';

export const useCarousel = (slides: Array<React.ReactNode>, drag: boolean = false) => {
  const [[page, direction], setPage] = React.useState([0, 0]);

  const slideIndex = wrap(0, slides.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return {
    slideNext: () => paginate(1),
    slidePrev: () => paginate(-1),
    activeIndex: slideIndex,
    carousel: (
      <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
        <motion.div
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          drag={drag ? 'x' : false}
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(_, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
          transition={{
            x: {
              type: 'spring',
              delay: 0,
              stiffness: 500,
              damping: 40,
              mass: 1,
            },
            opacity: { duration: 0.2 },
          }}
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
          {slides[slideIndex]}
        </motion.div>
      </AnimatePresence>
    ),
  };
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 200 : -200,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 200 : -200,
      opacity: 0,
    };
  },
};

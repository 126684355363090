import Markdown from 'react-markdown';
import { TranslationStrings } from 'src/core/Translation';
import { useTranslationKey } from 'src/hooks/useTranslationKey';

type Translation = {
  id: keyof TranslationStrings;
  [key: string]: string;
};

export const Translation = ({ id, ...props }: Translation): JSX.Element => {
  const text = useTranslationKey({ id, props });

  return (
    <Markdown disallowedTypes={['paragraph']} unwrapDisallowed linkTarget="_blank">
      {text}
    </Markdown>
  );
};
